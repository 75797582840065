import ModalOpeningRestrictions from "./Modal/ModalDepositIncomingCheck";
import TableOpeningRestrictions from "./TableDepositIncomingCheck";

function IndexDepositIncomingCheck() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexDepositIncomingCheck;
