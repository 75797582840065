import React, { useState } from "react";
import {
  Button,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Table,
  TableProps,
} from "antd";
import "./ItemTypeCodes.css";
import { DataType } from "./Type";

// select

const onSearch = (value: string) => {
  console.log("search:", value);
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

function ItemTypeCodes() {
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "كود النوع",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "اسم النوع عربى",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "كود الحساب الاعلى",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "اسم النوع انجليزى",
      key: "tags",
      dataIndex: "tags",
    },
    {
      title: "النوع الأعلى",
      key: "tags",
      dataIndex: "tags",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary">تعديل</Button>
          <Button type="primary" danger>
            حذف
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: "nice developer",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: "loser",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "4",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "4",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "4",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
  ];

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // slect
  const onChangeSelect = (value: string) => {
    console.log(`selected ${value}`);
  };

  // radio

  const [valueRadio, setValueRadio] = useState(1);

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValueRadio(e.target.value);
  };

  return (
    <div className="flex flex-wrap gap-5 mt-5">
      <div className="grid justify-start">
        <Button className="w-[150px]" type="primary" onClick={showModal}>
          إضافه
        </Button>
      </div>
      <Modal
        width={700}
        title="أكواد انواع الاصناف"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="خروج"
        okText="اضافه"
      >
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-3">
            <label className="w-[160px]" htmlFor="AccountCode">
              كود النوع
            </label>
            <InputNumber id="AccountCode" className="w-full" />
          </div>
          <div className="flex items-center gap-3">
            <label className="w-[160px]" htmlFor="AccountNameInArabic">
              اسم النوع بالعربي
            </label>
            <Input id="AccountNameInArabic" />
          </div>
          <div className="flex items-center gap-3">
            <label className="w-[160px]" htmlFor="AccountNameInEnglish">
              اسم النوع بالانجليزيه
            </label>
            <Input id="AccountNameInEnglish" />
          </div>
          <div className="flex items-center gap-3">
            <label className="w-[160px]" htmlFor="TheHighestAccount">
              الحساب الاعلى
            </label>
            <Select
              showSearch
              className="w-full"
              id="Branch"
              placeholder="الفرع"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
        </div>
      </Modal>
      <Table
        className="table-accounts-tree"
        columns={columns}
        dataSource={data}
        bordered
        scroll={{ y: 300, x: 1200 }}
        pagination={false}
      />
    </div>
  );
}

export default ItemTypeCodes;
