import React, { useRef, useState } from "react";
import type {
  InputNumberProps,
  InputRef,
  TableColumnsType,
  TableColumnType,
} from "antd";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Button,
  DatePicker,
  Select,
  Space,
} from "antd";
import { DataIndex, DataType } from "../Type";
import TextArea from "antd/es/input/TextArea";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  FilterDropdownProps,
  TableRowSelection,
} from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const onChangeInputNumber: InputNumberProps["onChange"] = (value) => {
  console.log("changed", value);
};

const data: DataType[] = [
  {
    key: "1",
    column_1: "1",
    column_2: "John Brown",
    column_3: "32",
    column_4: "New York No. 1 Lake Park",
    column_5: "New York No. 1 Lake Park",
    column_6: "New York No. 1 Lake Park",
    column_7: "New York No. 1 Lake Park",
    column_8: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    column_1: "2",
    column_2: "Jim Green",
    column_3: "42",
    column_4: "London No. 1 Lake Park",
    column_5: "London No. 1 Lake Park",
    column_6: "London No. 1 Lake Park",
    column_7: "London No. 1 Lake Park",
    column_8: "London No. 1 Lake Park",
  },
  {
    key: "3",
    column_1: "3",
    column_2: "Joe Black",
    column_3: "32",
    column_4: "Sydney No. 1 Lake Park",
    column_5: "Sydney No. 1 Lake Park",
    column_6: "Sydney No. 1 Lake Park",
    column_7: "Sydney No. 1 Lake Park",
    column_8: "Sydney No. 1 Lake Park",
  },
];

function ReceivingTheWorkOrder() {
  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "رقم امر الشغل",
      dataIndex: "column_2",
      key: "column_2",
      width: 130,
      ...getColumnSearchProps("column_2"),
      sorter: (a, b) => a.column_2.length - b.column_2.length,
    },
    {
      title: "تاريخ الامر ",
      dataIndex: "column_3",
      key: "column_3",
      width: 150,
      ...getColumnSearchProps("column_3"),
      sorter: (a, b) => a.column_3.length - b.column_3.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "التاريخ",
      dataIndex: "column_4",
      key: "column_4",
      width: 130,
      ...getColumnSearchProps("column_4"),
      sorter: (a, b) => a.column_4.length - b.column_4.length,
    },
    {
      title: "اسم الصنف",
      dataIndex: "column_5",
      key: "column_5",
      width: 140,
      ...getColumnSearchProps("column_5"),
      sorter: (a, b) => a.column_5.length - b.column_5.length,
    },
    {
      title: "الكميه",
      dataIndex: "column_6",
      key: "column_6",
      width: 130,
      ...getColumnSearchProps("column_6"),
      sorter: (a, b) => a.column_6.length - b.column_6.length,
    },
    {
      title: "ملاحظات",
      dataIndex: "column_7",
      key: "column_7",
      width: 130,
    },
    {
      title: "",
      dataIndex: "Events",
      key: "Events",
      render: (_, record) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          <Button type="primary">تعديل الخامات </Button>
          <Button type="primary">طياعه</Button>
        </div>
      ),
      width: 220,
    },
  ];

  const rowSelection: TableRowSelection<DataType> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  // form
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const [checkStrictly, setCheckStrictly] = useState(false);
  return (
    <div>
      {/* start data entry  */}
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 mt-10"
      >
        <div className="">
          <Form.Item label="رقم ألامر">
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item label="التاريخ / الوقت">
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item label="أمر توريد">
            <Select
              showSearch
              placeholder="أمر توريد"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item label="الورديه">
            <Select
              showSearch
              placeholder="الورديه"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="صاله الانتاج">
            <Select
              showSearch
              placeholder="صاله الانتاج"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item label="مركز التكلفه">
            <Select
              showSearch
              placeholder=""
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="مخزن الخامات">
            <Select
              showSearch
              placeholder="مخزن الخامات"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
        </div>
      </Form>
      {/* table */}
      <Table
        className="mt-10"
        columns={columns}
        dataSource={data}
        rowSelection={{ ...rowSelection, checkStrictly }}
        scroll={{ x: 1500, y: 250 }}
        bordered
        pagination={false}
      />
      {/* end */}
      <div className="mt-3">
        <div className="flex flex-nowrap items-center gap-5 mb-5">
          <label htmlFor="YourFeedback">ملاحظاتك</label>
          <TextArea id="YourFeedback" rows={4} placeholder="اكتب ملاحظاتك" />
        </div>
        <div className="flex justify-end items-center mt-5">
          <Button
            className="h-10 w-[200px] flex items-center font-bold justify-center"
            type="primary"
          >
            حفظ
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReceivingTheWorkOrder;
