import React, { useRef, useState } from "react";
import {
  Input,
  Table,
  Button,
  Select,
  Space,
  Upload,
  Checkbox,
  Form,
  InputNumber,
  DatePicker,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ColumnType } from "antd/es/table";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { DataType } from "../Type";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const initialData: DataType[] = [
  {
    key: "1",
    column_1: "1",
    column_2: "John Brown",
    column_3: "32",
    column_4: "New York No. 1 Lake Park",
    column_5: "New York No. 1 Lake Park",
    column_6: "New York No. 1 Lake Park",
    column_7: "New York No. 1 Lake Park",
    column_8: false, // قيمة منطقية من نوع boolean
  },
  {
    key: "1",
    column_1: "1",
    column_2: "John Brown",
    column_3: "32",
    column_4: "New York No. 1 Lake Park",
    column_5: "New York No. 1 Lake Park",
    column_6: "New York No. 1 Lake Park",
    column_7: "New York No. 1 Lake Park",
    column_8: false, // قيمة منطقية من نوع boolean
  },
  {
    key: "1",
    column_1: "1",
    column_2: "John Brown",
    column_3: "32",
    column_4: "New York No. 1 Lake Park",
    column_5: "New York No. 1 Lake Park",
    column_6: "New York No. 1 Lake Park",
    column_7: "New York No. 1 Lake Park",
    column_8: false, // قيمة منطقية من نوع boolean
  },
];

const Examination: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // حالة البيانات
  const [data, setData] = useState<DataType[]>(initialData);

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: keyof DataType
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: keyof DataType
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleCheckboxChange = (key: string, e: any) => {
    const newData = data.map((item) => {
      if (item.key === key) {
        return { ...item, column_8: e.target.checked };
      }
      return item;
    });
    setData(newData); // تحديث حالة البيانات
  };

  const columns: ColumnType<DataType>[] = [
    {
      title: "رقم امر الشغل",
      dataIndex: "column_2",
      key: "column_2",
      width: 130,
      ...getColumnSearchProps("column_2"),
      sorter: (a, b) => a.column_2.length - b.column_2.length,
    },
    {
      title: "تاريخ الامر ",
      dataIndex: "column_3",
      key: "column_3",
      width: 150,
      ...getColumnSearchProps("column_3"),
      sorter: (a, b) => a.column_3.length - b.column_3.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "التاريخ",
      dataIndex: "column_4",
      key: "column_4",
      width: 130,
      ...getColumnSearchProps("column_4"),
      sorter: (a, b) => a.column_4.length - b.column_4.length,
    },
    {
      title: "اسم الصنف",
      dataIndex: "column_5",
      key: "column_5",
      width: 140,
      ...getColumnSearchProps("column_5"),
      sorter: (a, b) => a.column_5.length - b.column_5.length,
    },
    {
      title: "الكميه",
      dataIndex: "column_6",
      key: "column_6",
      width: 130,
      ...getColumnSearchProps("column_6"),
      sorter: (a, b) => a.column_6.length - b.column_6.length,
    },
    {
      title: "الكميه الصالحه",
      dataIndex: "column_7",
      key: "column_7",
      width: 130,
    },
    {
      title: "اغلاق الامر",
      dataIndex: "column_8",
      key: "column_8",
      width: 130,
      render: (_, record) => (
        <Checkbox
          checked={record.column_8}
          onChange={(e) => handleCheckboxChange(record.key, e)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "Events",
      key: "Events",
      render: (_, record) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          <Button type="primary">تعديل الخامات </Button>
          <Button type="primary">طياعه</Button>
        </div>
      ),
      width: 220,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.column_2 === "Disabled User",
      name: record.column_2,
    }),
  };

  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const [checkStrictly, setCheckStrictly] = useState(false);
  return (
    <div>
      {/* start data entry  */}
      <Form
        labelCol={{ span: 8 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        className="grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 mt-10"
      >
        <div className="">
          <Form.Item label="رقم ألامر">
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item label="التاريخ / الوقت">
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item label="أمر الشغل">
            <Select
              showSearch
              placeholder="أمر الشغل"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
          <Form.Item label="رقم الفحص والاختبار">
            <Select
              showSearch
              placeholder="رقم الفحص والاختبار"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item label="الورديه">
            <Select
              showSearch
              placeholder="الورديه"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
          <Form.Item label="صاله الانتاج">
            <Select
              showSearch
              placeholder="صاله الانتاج"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
          <Form.Item label="لجنه الفحص">
            <Select
              showSearch
              placeholder="لجنه الفحص"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item label="مركز التكلفه">
            <Select
              showSearch
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
          <Form.Item label="مخزن الاستلام">
            <Select
              showSearch
              placeholder="مخزن الاستلام"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
          <Form.Item label="موقف امر الشغل">
            <Select
              showSearch
              placeholder="موقف امر الشغل"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "tom", label: "Tom" },
              ]}
            />
          </Form.Item>
        </div>
      </Form>
      {/* table */}
      <Table
        className="mt-5"
        columns={columns}
        dataSource={data}
        rowSelection={{ ...rowSelection, checkStrictly }}
        scroll={{ x: 1500, y: 250 }}
        bordered
        pagination={false}
      />
      {/* end */}
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-5">
          <Form.Item label="ملاحظات ">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="المرفقات"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card">
              <button style={{ border: 0, background: "none" }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>تحميل</div>
              </button>
            </Upload>
          </Form.Item>
        </div>
        <div className="flex justify-end items-center mt-5">
          <Button
            className="h-10 w-[200px] flex items-center font-bold justify-center"
            type="primary"
          >
            طباعه
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Examination;
