import {
  Button,
  Checkbox,
  CheckboxProps,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import "./Style.css";

const onChange: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

function CustomerCodes() {
  return (
    <>
      <div className="overflow-x-scroll h-scroll">
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          className="bg-white p-4 gap-3 grid w-[960px]"
        >
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              كود العميل{" "}
            </label>
            <InputNumber className="w-[300px]" />
            <Checkbox id="CustomerCodes" onChange={onChange}>
              وقف التعامل
            </Checkbox>
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              اسم العميل
            </label>
            <Input className="w-[700px]" />
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="ClientType">
              نوع العميل
            </label>
            <Input className="w-[200px]" id="ClientType" />
            <Select
              className="w-[200px]"
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <div className="flex flex-nowrap items-center">
              <label className="w-[80px] text-end" htmlFor="Source">
                المصدر
              </label>
              <Select
                id="Source"
                className="w-[200px]"
                showSearch
                placeholder="Select a person"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              العنوان
            </label>
            <Input className="w-[700px]" />
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              تلفون
            </label>
            <InputNumber className="w-[150px]" />
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <label className="w-[80px] text-end" htmlFor="CustomerCodes">
                موبيل
              </label>
              <InputNumber className="w-[150px]" />
            </div>
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <label className="w-[80px] text-end" htmlFor="CustomerCodes">
                فاكس
              </label>
              <InputNumber className="w-[150px]" />
            </div>
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              حد بطاقه الائتمان
            </label>
            <InputNumber className="w-[250px]" />
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <label className="w-[80px] text-end" htmlFor="CustomerCodes">
                فتره الائتمان
              </label>
              <InputNumber className="w-[250px]" />
            </div>
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              المنطقه
            </label>
            <Input className="w-[300px]" />
            <Select
              className="w-[300px]"
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              مندوب
            </label>
            <Input className="w-[300px]" />
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <Select
                className="w-[300px]"
                showSearch
                placeholder="Select a person"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </div>
            <Checkbox
              className="w-[200px]"
              id="CustomerCodes"
              onChange={onChange}
            >
              عميل / مورد
            </Checkbox>
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              العمله
            </label>
            <InputNumber className="w-[300px]" />
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <Select
                className="w-[250px]"
                showSearch
                placeholder="Select a person"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </div>
            <InputNumber className="w-[200px]" />
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              مركز التكلفه
            </label>
            <InputNumber className="w-[300px]" />
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <Select
                className="w-[250px]"
                showSearch
                placeholder="Select a person"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
              <Link className="font-bold" to={""}>
                شريحه الاسعار
              </Link>
            </div>
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="CustomerCodes">
              الحساب المرتبط
            </label>
            <InputNumber className="w-[300px]" />
            <Select
              className="w-[250px]"
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="w-[250px]"
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="flex flex-nowrap items-center justify-start gap-3">
            <label className="w-[150px] text-end" htmlFor="date">
              تاريخ
            </label>
            <DatePicker id="date" onChange={onChange} />
            <div className="flex flex-nowrap items-center justify-start gap-3">
              <label className="w-[80px] text-end" htmlFor="CustomerCodes">
                الرصيد
              </label>
              <InputNumber className="w-[200px]" />
            </div>
            <Radio.Group className="w-[150px]">
              <Radio value={1}>دائن</Radio>
              <Radio value={2}>مدين</Radio>
            </Radio.Group>
            <Select
              className="w-[200px]"
              showSearch
              placeholder="Select a person"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
        </Form>
      </div>
      <div className="text-end mt-2">
        <Button className="w-[300px] h-8" type="primary">
          حفظ
        </Button>
      </div>
    </>
  );
}

export default CustomerCodes;
