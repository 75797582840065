import { Button, Form, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import Heading from "components/Heading/Heading";
import React, { useState } from "react";

function GeneralSettings() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  // select
  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <>
      <Heading name={"اعدادات عامه"} />
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-1 md:grid-cols-2 mt-10"
      >
        <Form.Item label="مخزن الانتاج التام">
          <Select
            showSearch
            placeholder="مخزن الانتاج التام"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="مخزن  الفحص">
          <Select
            showSearch
            placeholder="مخزن  الفحص"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="مخزن تحت التشغيل">
          <Select
            showSearch
            placeholder="مخزن تحت التشغيل"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="مخزن  الخامات">
          <Select
            showSearch
            placeholder="مخزن  الخامات"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
      </Form>
      <div className="grid justify-end items-center">
        <Button
          type="primary"
          className="py-4 px-20 font-bold flex items-center justify-center"
        >
          حفظ
        </Button>
      </div>
    </>
  );
}

export default GeneralSettings;
