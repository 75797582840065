import Heading from "components/Heading/Heading";
import ModalCompanyNews from "./Modal/ModalCompanyNews";
import TableCompanyNews from "./TableCompanyNews";

function IndexCompanyNews() {
  return (
    <>
      <Heading name={"اخبار الشركه"} />
      <ModalCompanyNews />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableCompanyNews />
    </>
  );
}

export default IndexCompanyNews;
