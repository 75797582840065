import { Tabs, TabsProps } from "antd";
import Heading from "components/Heading/Heading";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import ShiftCodes from "./ShiftCodes/ShiftCodes";
import ProductionHallCodes from "./ProductionHallCodes/ProductionHallCodes";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "اعدادات عامه",
    children: <GeneralSettings />,
  },
  {
    key: "2",
    label: "اكواد الورديات",
    children: <ShiftCodes />,
  },
  {
    key: "3",
    label: "اكواد صالات الانتاج",
    children: <ProductionHallCodes />,
  },
];

function ProductionSettings() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"اعدادات  متابعه  الطلبيات"} />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        type="card"
        items={items}
      />
    </>
  );
}

export default ProductionSettings;
