import ModalFirstTime from "./Modal/ModalAddition";
import TableFirstTime from "./TableAddition";

function IndexAddition() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexAddition;
