import {
  Button,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values: any) => {
  console.log(values);
};

function UserCodesModle({ setUserCodes, UserCodes }) {
  return (
    <>
      <Modal
        title="اكود المتسخدمين"
        style={{ top: 20 }}
        open={UserCodes}
        onOk={() => setUserCodes(false)}
        onCancel={() => setUserCodes(false)}
        width={1000}
        footer={null}
      >
        <Form
          {...layout}
          name="nest-messages"
          className="grid grid-cols-2 px-5"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "Name"]}
            label="كود المستخدم"
            rules={[{ required: true, type: "number" }]}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <div className="hidden md:block"></div>
          <Form.Item
            name={["user", "NameUser"]}
            label="اسم المستخدام"
            rules={[{ required: true, type: "string" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["user", "email"]}
            label="البريد الاكتروني"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="كلمه المرور"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="password"
            label="تاكيد كلمه المرور"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="الاداره"
            name="Administration"
            rules={[{ required: true, message: "Please input!" }]}
          >
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="القسم"
            name="Section"
            rules={[{ required: true, message: "Please input!" }]}
          >
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="الواظيفه"
            name="Job"
            rules={[{ required: true, message: "Please input!" }]}
          >
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="الفرع"
            name="Branch"
            rules={[{ required: true, message: "Please input!" }]}
          >
            <Select>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <div className="flex justify-end items-center">
          <Button type="primary" className="w-[200px] font-bold">
            حفظ
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default UserCodesModle;
