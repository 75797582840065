import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  TreeSelect,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";

type SizeType = Parameters<typeof Form>[0]["size"];
const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

function CheckStore() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-3 gap-x-5"
      >
        <Form.Item label="رقم المستند">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label="شيكات تحت التحصيل">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="اول المده">
          <Checkbox />
        </Form.Item>
        <Form.Item label="تاريخ الايداع">
          <DatePicker className="w-full" />
        </Form.Item>
        <Form.Item label="جهه التعامل ">
          <Select
            defaultValue="lucy"
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "Yiminghe", label: "yiminghe" },
              { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Form.Item>
        <Form.Item label="بنك الايداع">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="كود الجهه">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="اسم الجهه">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="الرصيد">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label="القيمه">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label="تاريخ الشيك">
          <DatePicker className="w-full" />
        </Form.Item>
        <Form.Item label="تاريخ الاستحقاق">
          <DatePicker className="w-full" />
        </Form.Item>
        <Form.Item label="العمله">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="المعامل">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item label="اسم المندوب">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
      </Form>
      <div className="grid grid-cols-4 gap-5">
        <Form.Item label="ملاحظات " className="col-span-2">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="المرفقات"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="col-span-2"
        >
          <Upload action="/upload.do" listType="picture-card">
            <button style={{ border: 0, background: "none" }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>تحميل</div>
            </button>
          </Upload>
        </Form.Item>
      </div>
      <div className="grid justify-end items-center">
        <Button
          type="primary"
          className="py-5 px-36 font-bold flex items-center justify-center"
        >
          حفظ
        </Button>
      </div>
    </>
  );
}

export default CheckStore;
