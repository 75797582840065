import ModalCustomerCodes from "./Modal/ModalCustomerCodes";
import TableCustomerCodes from "./TableCustomerCodes";

function IndexCustomerCodesMaintenance() {
  return (
    <>
      <ModalCustomerCodes />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableCustomerCodes />
    </>
  );
}

export default IndexCustomerCodesMaintenance;
