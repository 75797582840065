import { Tabs, TabsProps } from "antd";
import AddPermissionsValue from "./AddPermissionsValue";
import UserPermissions from "./UserPermissions";
import AddMobilePermissions from "./AddMobilePermissions";
import ModifyingEmployeePowers from "./ModifyingEmployeePowers";
import PowersReport from "./PowersReport";
import Heading from "components/Heading/Heading";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "اضافه قايمه صلاحيات",
    children: <AddPermissionsValue />,
  },
  {
    key: "2",
    label: "صلاحيات المستخدمين",
    children: <UserPermissions />,
  },
  {
    key: "3",
    label: "اضافه صلاحيات الموبيل",
    children: <AddMobilePermissions />,
  },
  {
    key: "4",
    label: "تعديل صلاحيات المواظفين",
    children: <ModifyingEmployeePowers />,
  },
  {
    key: "5",
    label: "تقرير الصلاحيات",
    children: <PowersReport />,
  },
];

function TabsUserPermissions() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"صلاحيات المستتخدمين"} />
      </div>
      <Tabs onChange={onChange} type="card" items={items} />;
    </>
  );
}

export default TabsUserPermissions;
