import ModalOpeningRestrictions from "./Modal/ModalIssuedBouncedChecks";
import TableOpeningRestrictions from "./TableIIssuedBouncedChecks";

function IndexIssuedBouncedChecks() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexIssuedBouncedChecks;
