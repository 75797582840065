import React, { useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

type SizeType = Parameters<typeof Form>[0]["size"];
const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

function AdditionalChipsAndModules() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <>
      <Form
        labelCol={{ span: 10 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-3 gap-x-5"
      >
        <Form.Item label="وحده القياس الاساسه">
          <Select
            showSearch
            placeholder=""
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="وحده القياس  الاضافيه">
          <Select
            showSearch
            placeholder=""
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="الكميه من الوحده الاساسيه">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="سعر البيع للوحده الرئسيه ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="سعر البيع للوحده الفرعيه ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="سعر جمله ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="سعر قطاعى ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="سعر خاص ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="اقل سعر بيع">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="اعلى سعر بيع">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="خصم اساسى نسبه %">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="اعلى نسبه خصم نسبه %">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="ضريبه القيمه المضافه نسبه %">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="ضريبه الخصم  نسبه %">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="عموله قيمه ">
          <Input className="w-full" />
        </Form.Item>
        <Form.Item label="عموله نسبه">
          <Input className="w-full" />
        </Form.Item>
      </Form>
      <div className="grid justify-end items-center">
        <Button
          type="primary"
          className="py-5 px-36 font-bold flex items-center justify-center"
        >
          حفظ
        </Button>
      </div>
    </>
  );
}

export default AdditionalChipsAndModules;
