import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalContractorExtract";
import TableFirstTime from "./TableContractorExtract";

function IndexContractorExtract() {
  return (
    <>
      <Heading name={"مستخلص مقاول"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexContractorExtract;
