import ModalFirstTime from "./Modal/ModalApostateCashing";
import TableFirstTime from "./TableApostateCashing";

function IndexApostateCashing() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexApostateCashing;
