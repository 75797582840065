import { DatePicker, InputNumber, InputNumberProps, Select } from "antd";

const onChange: InputNumberProps["onChange"] = (value) => {
  console.log("changed", value);
};

// selct

const onChangeSelect = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

// Filter `option.label` match the user type `input`
const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

function ModalEntry() {
  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-3">
        <div className="grid grid-cols-3 items-center">
          <label className="text-center" htmlFor="Branch">
            الفرع
          </label>
          <Select
            showSearch
            className="col-span-2"
            id="Branch"
            placeholder="الفرع"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center">
          <label className="text-center" htmlFor="invoiceNumber">
            رقم الامر
          </label>
          <InputNumber
            placeholder="رقم الفاتورة"
            id="invoiceNumber"
            defaultValue={0}
            className="w-[100%] col-span-2"
            onChange={onChange}
          />
        </div>
        <div className="grid grid-cols-3 items-center ">
          <label className="text-center" htmlFor="InvoiceDate">
            تاريخ الامر
          </label>
          <DatePicker className="w-[100%] col-span-2" id="InvoiceDate" />
        </div>
        <div className="grid grid-cols-3 items-center ">
          <label className="text-center" htmlFor="DateOfSupply">
            تاريخ التوريد
          </label>
          <DatePicker className="w-[100%] col-span-2" id="DateOfSupply" />
        </div>
        <div className="grid grid-cols-4 items-center">
          <label className="text-center" htmlFor="Client">
            المورد
          </label>
          <div className="grid grid-cols-3 items-center">
            <InputNumber
              placeholder="كود"
              id="CostCode"
              defaultValue={0}
              className="w-[100%] col-span-2"
              onChange={onChange}
            />
          </div>
          <Select
            showSearch
            className="col-span-2"
            id="Client"
            placeholder="المورد"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center">
          <label className="text-center" htmlFor="Branch">
            المندوب
          </label>
          <Select
            showSearch
            className="col-span-2"
            id="Branch"
            placeholder="المندوب"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-3">
        <div className="grid grid-cols-3 items-center">
          <label className="text-center" htmlFor="CostCenter">
            مركز التكلفه
          </label>
          <Select
            showSearch
            className="col-span-2"
            id="Branch"
            placeholder="مركز التكلفه"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>

        <div className="grid grid-cols-3 items-center">
          <label className="text-center" htmlFor="CenterName">
            فرع المورد
          </label>
          <Select
            showSearch
            className="col-span-2"
            id="Branch"
            placeholder="فرع المورد"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center ">
          <label className="text-center" htmlFor="DueDate">
            تاريخ الاستحقاق
          </label>
          <DatePicker className="w-[100%] col-span-2" id="DueDate" />
        </div>

        <div className="grid grid-cols-3 items-center">
          <label className="text-center" htmlFor="SupplyOrderNumber">
            رقم طلب الشراء 
          </label>
          <Select
            showSearch
            className="col-span-2"
            id="Branch"
            placeholder="رقم طلب الشراء"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default ModalEntry;
