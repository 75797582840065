import { Button, Modal } from "antd";
import TableData from "./TableData";
import { useState } from "react";
import excel from "../../../assets/excel.svg";
import Heading from "components/Heading/Heading";
import ModalEntry from "./Modal/ModalEntry";
import TableDataCopy from "./Modal/TableDataCopy";
import LastDataEntry from "./Modal/LastDataEntry";
import BasicButton from "components/BasicButton/BasicButton";

function PurchaseOrder() {
  const [openAddAccount, setOpenAddAccount] = useState(false);
  return (
    <>
      <Heading name={"امر شراء"} />
      <BasicButton>
        <Button
          className="w-[150px] font-semibold"
          type="primary"
          onClick={() => setOpenAddAccount(true)}
        >
          اضافه
        </Button>
      </BasicButton>
      <div className="flex flex-col gap-5">
        <TableData />
        <Modal
          title="انشاء امر شراء"
          style={{ top: 20 }}
          open={openAddAccount}
          onOk={() => setOpenAddAccount(false)}
          onCancel={() => setOpenAddAccount(false)}
          footer={null}
          width={1300}
        >
          <ModalEntry />
          <TableDataCopy />
          <LastDataEntry />
        </Modal>
      </div>
    </>
  );
}

export default PurchaseOrder;
