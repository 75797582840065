import { Tabs, TabsProps } from "antd";
import Heading from "components/Heading/Heading";
import ShippingCompanyCodes from "./ShippingCompanyCodes/ShippingCompanyCodes";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "اكواد شركات الشحن",
    children: <ShippingCompanyCodes />,
  },
  {
    key: "2",
    label: "tab 2",
  },
  {
    key: "3",
    label: " tab 3",
  },
];

function FollowUpOnOrders() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"اعدادات  متابعه  الطلبيات"} />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        type="card"
        items={items}
      />
    </>
  );
}

export default FollowUpOnOrders;
