import React, { useState } from "react";
import { Button, Checkbox, Form } from "antd";
import ItemComponents from "./ItemComponents/ItemComponents";
import ClassColors from "./ClassColors/ClassColors";
import ItemSizes from "./ItemSizes/ItemSizes";

type SizeType = Parameters<typeof Form>[0]["size"];

function OtherData() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-5"
      >
        <Form.Item label="له تاريخ صلاحيه ">
          <Checkbox />
        </Form.Item>
        <Form.Item label="له رقم قطعه ">
          <Checkbox />
        </Form.Item>
        <Form.Item label="صنف ارشيف">
          <Checkbox />
        </Form.Item>
        <Form.Item label="له مسلسل">
          <Checkbox />
        </Form.Item>
        <Form.Item label="صنف خدمه">
          <Checkbox />
        </Form.Item>
        <Form.Item label="ماده خام">
          <Checkbox />
        </Form.Item>
        <Form.Item label="منتج نهائى">
          <Checkbox />
        </Form.Item>
      </Form>

      {/*  */}
      {/* جدول مكونات الصنف */}
      <ItemComponents />
      {/* جدول  اللوان الصنف */}
      <ClassColors />
      {/* جدول  مقاسات الصنف */}
      <ItemSizes />
      {/*  */}

      <div className="grid justify-end items-center">
        <Button
          type="primary"
          className="py-5 px-36 font-bold flex items-center justify-center"
        >
          حفظ
        </Button>
      </div>
    </>
  );
}

export default OtherData;
