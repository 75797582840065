import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalReceivingTheWorkOrder";
import TableFirstTime from "./TableReceivingTheWorkOrder";

function IndexReceivingTheWorkOrder() {
  return (
    <>
      <Heading name={"استلام امر شغل"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexReceivingTheWorkOrder;
