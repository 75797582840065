import { Button, Modal } from "antd";
import { useState } from "react";
import BasicButton from "components/BasicButton/BasicButton";
import FirstTime from "./Examination";

function ModalExamination() {
  const [openModalOpeningRestrictions, setOpenModalOpeningRestrictions] =
    useState(false);

  return (
    <>
      <BasicButton>
        <Button
          className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800 w-[150px]"
          type="primary"
          onClick={() => setOpenModalOpeningRestrictions(true)}
        >
          اضافه
        </Button>
      </BasicButton>
      <Modal
        title="استلام امر شغل"
        style={{ top: 20 }}
        open={openModalOpeningRestrictions}
        onOk={() => setOpenModalOpeningRestrictions(false)}
        onCancel={() => setOpenModalOpeningRestrictions(false)}
        width={1200}
        footer={false}
      >
        <FirstTime />
      </Modal>
    </>
  );
}

export default ModalExamination;
