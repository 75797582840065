import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import BasicButton from "components/BasicButton/BasicButton";
import FirstTime from "./ProjectsManagement";

function ModalProjectsManagement({ openModal, setOpenModal }) {
  const [openModalOpeningRestrictions, setOpenModalOpeningRestrictions] =
    useState(false);

  useEffect(() => {
    if (openModal) {
      setOpenModalOpeningRestrictions(true);
    } else {
      setOpenModalOpeningRestrictions(false);
    }
  }, [openModal]);

  return (
    <>
      <BasicButton children={undefined} />
      <Modal
        title="تعيين مهمه"
        style={{ top: 20 }}
        open={openModalOpeningRestrictions}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={800}
        footer={false}
      >
        <FirstTime />
      </Modal>
    </>
  );
}

export default ModalProjectsManagement;
