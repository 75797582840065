import ModalFirstTime from "./Modal/ModalFirstTime";
import TableFirstTime from "./TableFirstTime";

function IndexFirstTime() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexFirstTime;
