import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Table,
  TableProps,
} from "antd";
import "./AccountsTree.css";
import { DataType } from "./Type";
import Heading from "components/Heading/Heading";

// select

const onSearch = (value: string) => {
  console.log("search:", value);
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

function AccountsTree() {
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "كود الحساب",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "اسم الحساب",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "كود الحساب الاعلى",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "اسم الحساب الاعلى",
      key: "tags",
      dataIndex: "tags",
    },
    {
      title: "نوع الحساب",
      key: "tags",
      dataIndex: "tags",
    },
    {
      title: "مجموعه الحساب",
      key: "tags",
      dataIndex: "tags",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary">تعديل</Button>
          <Button type="primary" danger>
            حذف
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: "nice developer",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: "loser",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "4",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "4",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
    {
      key: "4",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: "cool teacher",
    },
  ];

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // slect
  const onChangeSelect = (value: string) => {
    console.log(`selected ${value}`);
  };

  // radio

  const [valueRadio, setValueRadio] = useState(1);

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValueRadio(e.target.value);
  };

  return (
    <>
      <Heading name={"شجره الحسابات"} />
      <div className="flex flex-wrap gap-10">
        <div className="grid justify-start">
          <Button type="primary" onClick={showModal}>
            إضافه
          </Button>
        </div>
        <Modal
          width={1000}
          title="اضافه حساب"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText="خروج"
          okText="اضافه"
        >
          <div className="flex flex-col gap-5">
            <div className="flex items-center gap-3">
              <label className="w-[160px]" htmlFor="AccountCode">
                كود الحساب
              </label>
              <Input id="AccountCode" />
            </div>
            <div className="flex items-center gap-3">
              <label className="w-[160px]" htmlFor="AccountNameInArabic">
                اسم الحساب بالعربي
              </label>
              <Input id="AccountNameInArabic" />
            </div>
            <div className="flex items-center gap-3">
              <label className="w-[160px]" htmlFor="AccountNameInEnglish">
                اسم الحساب بالانجليزيه
              </label>
              <Input id="AccountNameInEnglish" />
            </div>
            <div className="grid grid-cols-5 gap-10">
              <label className="w-[160px]" htmlFor="TheHighestAccount">
                الحساب الاعلى
              </label>
              <Select
                showSearch
                className="col-span-2"
                id="Branch"
                placeholder="الفرع"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
              <Select
                showSearch
                className="col-span-2"
                id="Branch"
                placeholder="الفرع"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </div>
            <div className="flex items-center gap-3">
              <label className="w-[160px]" htmlFor="AccountingGuidance">
                التوجيه المحاسبي
              </label>
              <Select
                showSearch
                className="w-full"
                id="Branch"
                placeholder="الفرع"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </div>
            {/* Radio */}
            <div className="grid grid-cols-1  sm:grid-cols-3 gap-5">
              <div className="border-[1px] border-solid border-primary rounded-md px-5 py-3">
                <h2 className="mb-3">طبيعه الحساب:-</h2>
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                  <Space direction="vertical">
                    <Radio value={1}>مدين</Radio>
                    <Radio value={2}>دائن</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div className="border-[1px] border-solid border-primary rounded-md px-5 py-3">
                <h2 className="mb-3">نوع الحساب:-</h2>
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                  <Space direction="vertical">
                    <Radio value={1}>رئسي</Radio>
                    <Radio value={2}>فرعي</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div className="border-[1px] border-solid border-primary rounded-md px-5 py-3">
                <h2 className="mb-3">مجموعه الحساب:-</h2>
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                  <Space direction="vertical">
                    <Radio value={1}>اصول</Radio>
                    <Radio value={2}>خصوم</Radio>
                    <Radio value={3}>مصروفات</Radio>
                    <Radio value={4}>ايرادات</Radio>
                    <Radio value={5}>اخري</Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
          </div>
        </Modal>
        <Table
          className="table-accounts-tree"
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ y: 350, x: 1300 }}
          pagination={false}
        />
      </div>
    </>
  );
}

export default AccountsTree;
