import { Button, Modal } from "antd";
import { useState } from "react";
import OpeningRestrictions from "./OpeningRestrictions";
import excel from "../../../../assets/excel.svg";

function ModalOpeningRestrictions() {
  const [openModalOpeningRestrictions, setOpenModalOpeningRestrictions] =
    useState(false);

  return (
    <>
      <div className="text-start">
        <div className="text-start gap-x-2 gap-y-1 flex flex-wrap items-center">
          <Button
            className="w-[150px] h-8"
            type="primary"
            onClick={() => setOpenModalOpeningRestrictions(true)}
          >
            اضافه
          </Button>
          <button
            type="button"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          >
            بيانات اليوم الحالي
          </button>
          <button
            type="button"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          >
            بيانات الشهر الحالي
          </button>
          <button
            type="button"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          >
            بيانات السنه الحاليه
          </button>
          <button
            type="button"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          >
            كل البيانات
          </button>
          <button
            type="button"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          >
            رفع قيود مجمعه
          </button>
          <img className="cursor-pointer" src={excel} alt="excel" />
        </div>
      </div>
      <Modal
        title="اضافه قيد اول المده"
        style={{ top: 20 }}
        open={openModalOpeningRestrictions}
        onOk={() => setOpenModalOpeningRestrictions(false)}
        onCancel={() => setOpenModalOpeningRestrictions(false)}
        width={1200}
        footer={false}
      >
        <OpeningRestrictions />
      </Modal>
    </>
  );
}

export default ModalOpeningRestrictions;
