import React, { useState } from "react";
import type { TableProps } from "antd";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Button,
  Slider,
  Switch,
  Radio,
  Checkbox,
  DatePicker,
  Select,
} from "antd";
import { EditableCellProps, Item } from "./Type";
const originData: Item[] = [];

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : inputType === "select" ? (
      <Select
        showSearch
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={[
          {
            value: "jack",
            label: "Jack",
          },
          {
            value: "lucy",
            label: "Lucy",
          },
          {
            value: "tom",
            label: "Tom",
          },
        ]}
      />
    ) : inputType === "text" ? (
      <Input />
    ) : inputType === "date" ? (
      <DatePicker />
    ) : inputType === "checkbox" ? (
      <Checkbox />
    ) : inputType === "radio" ? (
      <Radio.Group
        options={[
          { label: "Option 1", value: 1 },
          { label: "Option 2", value: 2 },
        ]}
      />
    ) : inputType === "switch" ? (
      <Switch />
    ) : inputType === "slider" ? (
      <Slider />
    ) : null;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function TypesCovenant() {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [count, setCount] = useState(2);

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRow = (key: React.Key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const addNewRow = () => {
    const newRow: Item = {
      key: count.toString(),
      Column_1: `1`,
      Column_2: "كود النوع",
      Column_3: `اسم نوع السلفه  عربى`,
      Column_4: `اسم نوع  السلفه  انجليزى`,
    };
    setData([...data, newRow]);
    edit(newRow);
    setCount(count + 1);
  };

  const columns = [
    {
      title: "م",
      dataIndex: "Column_1",
      width: 20,
      editable: false,
    },
    {
      title: "كود النوع",
      dataIndex: "Column_2",
      width: 130,
      editable: true,
    },
    {
      title: "اسم  اسم نوع العهده  عربى",
      dataIndex: "Column_3",
      width: 130,
      editable: true,
    },
    {
      title: "اسم نوع  العهده  انجليزى",
      dataIndex: "Column_4",
      width: 100,
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: 140,
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="grid grid-cols-2 gap-2">
            <Button onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Button>

            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button>Cancel</Button>
            </Popconfirm>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2">
            <Button
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
              disabled={editingKey !== ""}
            >
              Edit
            </Button>

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteRow(record.key)}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType:
          col.dataIndex === "Column_1"
            ? "text"
            : col.dataIndex === "Column_2"
            ? "text"
            : col.dataIndex === "Column_3"
            ? "text"
            : col.dataIndex === "Column_4"
            ? "text"
            : col.dataIndex === "Column_5"
            ? "text"
            : col.dataIndex === "Column_6"
            ? "text"
            : col.dataIndex === "Column_7"
            ? "text"
            : col.dataIndex === "Column_8"
            ? "text"
            : null,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 items-center gap-2 my-5">
        <Button onClick={addNewRow} type="primary">
          اضافه
        </Button>
      </div>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 1000, y: 300 }}
      />
    </Form>
  );
}

export default TypesCovenant;
