import { Tabs, TabsProps } from "antd";
import Heading from "components/Heading/Heading";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import StoreCodes from "./StoreCodes/StoreCodes";
import ItemTypeCodes from "./ItemTypeCodes/ItemTypeCodes";
import MeasurementUnitCodes from "./MeasurementUnitCodes/MeasurementUnitCodes";
import SizeCodes from "./SizeCodes/SizeCodes";
import ColorCodes from "./ColorCodes/ColorCodes";
import CountryCodesOrigin from "./CountryCodesOrigin/CountryCodesOrigin";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "اعدادت عامه",
    children: <GeneralSettings />,
  },
  {
    key: "2",
    label: "اكواد المخازن",
    children: <StoreCodes />,
  },
  {
    key: "3",
    label: "أكواد انواع الاصناف",
    children: <ItemTypeCodes />,
  },
  {
    key: "4",
    label: "اكواد وحدات القياس",
    children: <MeasurementUnitCodes />,
  },
  {
    key: "5",
    label: "اكواد المقاسات",
    children: <SizeCodes />,
  },
  {
    key: "6",
    label: "اكواد الالوان",
    children: <ColorCodes />,
  },
  {
    key: "7",
    label: "اكواد بلدان المنشأ  ",
    children: <CountryCodesOrigin />,
  },
];

function StoresSettings() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"اعدادات المخازن"} />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        type="card"
        items={items}
      />
    </>
  );
}

export default StoresSettings;
