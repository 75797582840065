import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalCustomerCRM";
import TableFirstTime from "./TableCustomerCRM";

function IndexCustomerCRM() {
  return (
    <>
      <Heading name={"العملاء"} />
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexCustomerCRM;
