import React from "react";
import { Layout, theme } from "antd";
import { Outlet } from "react-router-dom";
import HeaderComponents from "components/Header/Header";
import FooterComponents from "components/Footer/FooterComponents";
import SiderComponents from "components/Sider/SiderComponents";
// import HeaderComponents from "components/Header/Header";
// import FooterComponents from "components/Footer/FooterComponents";
// import SiderComponents from "components/Sider/SiderComponents";

const { Content } = Layout;

const App: React.FC = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout hasSider>
      <Layout style={{ marginRight: 230 }}>
        {/* Header */}
        <HeaderComponents />
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <div
            style={{
              padding: 24,
              textAlign: "center",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </div>
        </Content>
        {/* Footer */}
        <FooterComponents />
      </Layout>
      {/* Sider */}
      <SiderComponents />
    </Layout>
  );
};

export default App;
