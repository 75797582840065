import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalAddData";
import TableFirstTime from "./TableAddData";

function IndexAddData() {
  return (
    <>
      <Heading name={"اضافه بيانات"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexAddData;
