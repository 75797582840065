import React from "react";
import ModalSupplierCodes from "./Modal/ModalSupplierCodes";
import TableSupplierCodes from "./TableSupplierCodes";

function IndexSupplierCodes() {
  return (
    <>
      <ModalSupplierCodes />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableSupplierCodes />
    </>
  );
}

export default IndexSupplierCodes;
