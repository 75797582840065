import ModalFirstTime from "./Modal/ModalExchangeRequest";
import TableFirstTime from "./TableExchangeRequest";

function IndexExchangeRequest() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexExchangeRequest;
