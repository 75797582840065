import Heading from "components/Heading/Heading";
import ModalOpeningRestrictions from "./Modal/ModalOpeningRestrictions";
import TableOpeningRestrictions from "./TableOpeningRestrictions";

function IndexOpeningRestrictions() {
  return (
    <>
      <Heading name={"القيود الافتتاحيه"} />
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexOpeningRestrictions;
