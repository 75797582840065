import ModalFirstTime from "./Modal/ModalReceiveTheFinishedProduct";
import TableFirstTime from "./TableReceiveTheFinishedProduct";

function IndexReceiveTheFinishedProduct() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexReceiveTheFinishedProduct;
