import React, { useState } from "react";
import type { InputNumberProps, TableProps } from "antd";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Button,
  Slider,
  Switch,
  Radio,
  Checkbox,
  DatePicker,
  Select,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { EditableCellProps, ItemAddRequest } from "../Type";
import TextArea from "antd/es/input/TextArea";
import AddAccountingGuidance from "./AddAccountingGuidance/AddAccountingGuidance";

const originData: ItemAddRequest[] = [];

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const onChangeInputNumber: InputNumberProps["onChange"] = (value) => {
  console.log("changed", value);
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber className="w-full" />
    ) : inputType === "select" ? (
      <Select
        showSearch
        placeholder="Select a person"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={[
          {
            value: "jack",
            label: "Jack",
          },
          {
            value: "lucy",
            label: "Lucy",
          },
          {
            value: "tom",
            label: "Tom",
          },
        ]}
      />
    ) : inputType === "text" ? (
      <Input />
    ) : inputType === "date" ? (
      <DatePicker />
    ) : inputType === "checkbox" ? (
      <Checkbox />
    ) : inputType === "radio" ? (
      <Radio.Group
        options={[
          { label: "Option 1", value: 1 },
          { label: "Option 2", value: 2 },
        ]}
      />
    ) : inputType === "switch" ? (
      <Switch />
    ) : inputType === "slider" ? (
      <Slider />
    ) : null;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function ApostateCashing() {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [count, setCount] = useState(2);

  const isEditing = (record: ItemAddRequest) => record.key === editingKey;

  const edit = (record: Partial<ItemAddRequest> & { key: React.Key }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ItemAddRequest;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRow = (key: React.Key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const addNewRow = () => {
    const newRow: ItemAddRequest = {
      key: count.toString(),
      column_1: `1`,
      column_2: ``,
      column_3: ``,
      column_4: ``,
      column_5: ``,
      column_6: ``,
      column_7: ``,
      column_8: ``,
      column_9: ``,
      column_10: ``,
      column_11: ``,
      column_12: ``,
    };
    setData([...data, newRow]);
    edit(newRow);
    setCount(count + 1);
  };

  const columns = [
    {
      title: "م",
      dataIndex: "column_1",
      width: 20,
      editable: false,
    },
    {
      title: "كود الصنف",
      dataIndex: "column_2",
      width: 170,
      editable: true,
    },
    {
      title: "اسم الصنف",
      dataIndex: "column_3",
      width: 170,
      editable: true,
    },
    {
      title: "الوحده",
      dataIndex: "column_4",
      width: 120,
      editable: true,
    },
    {
      title: "الكميه",
      dataIndex: "column_5",
      width: 120,
      editable: true,
    },
    {
      title: "السعر",
      dataIndex: "column_6",
      width: 120,
      editable: true,
    },
    {
      title: "الاجمالى",
      dataIndex: "column_7",
      width: 120,
      editable: true,
    },
    {
      title: "رصيد الصنف",
      dataIndex: "column_7",
      width: 120,
      editable: true,
    },
    {
      title: "ملاحظات",
      dataIndex: "column_8",
      width: 200,
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 170,
      render: (_: any, record: ItemAddRequest) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="grid grid-cols-2 gap-2">
            <Button onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Button>

            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button>Cancel</Button>
            </Popconfirm>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2">
            <Button
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
              disabled={editingKey !== ""}
            >
              Edit
            </Button>

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteRow(record.key)}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ItemAddRequest) => ({
        record,
        inputType:
          col.dataIndex === "column_2"
            ? "select"
            : col.dataIndex === "column_3"
            ? "select"
            : col.dataIndex === "column_4"
            ? "select"
            : col.dataIndex === "column_5"
            ? "number"
            : col.dataIndex === "column_6"
            ? "number"
            : col.dataIndex === "column_7"
            ? "number"
            : col.dataIndex === "column_7"
            ? "text"
            : null,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      {/* start data entry  */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-3">
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="invoiceNumber">
            رقم الاذن
          </label>
          <InputNumber
            placeholder="رقم الاذن"
            id="invoiceNumber"
            className="w-[100%] col-span-2"
            onChange={onChangeInputNumber}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            نوع الجهه
          </label>
          <Select
            className="w-full col-span-2"
            defaultValue="lucy"
            style={{ width: 120 }}
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "Yiminghe", label: "yiminghe" },
              { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            تاريخ الطلب
          </label>
          <DatePicker
            className="w-[100%] col-span-2"
            id="InvoiceDate"
            placeholder="تاريخ القيد "
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            اسم الجهه
          </label>
          <Select
            showSearch
            className="w-full col-span-2"
            placeholder="اسم الجهه"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            مركز التكلفه
          </label>
          <Select
            showSearch
            className="w-full col-span-2"
            placeholder="مركز التكلفه"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            الرقم التسلسلى
          </label>
          <InputNumber className="w-full col-span-2" />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            المخزن
          </label>
          <Select
            showSearch
            className="w-full col-span-2"
            placeholder="المخزن"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="grid grid-cols-3 items-center gap-2">
          <label className="text-end" htmlFor="InvoiceDate">
            اذن الصرف
          </label>
          <Select
            showSearch
            className="w-full col-span-2"
            placeholder="اذن الصرف"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 items-center gap-2 mt-5">
        <Button onClick={addNewRow} type="primary">
          اضافه
        </Button>
      </div>
      {/* table */}
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        className="min-h-[250px]"
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 1100, y: 200 }}
      />
      {/* end */}
      <div className="mt-3">
        <div className="grid grid-cols-4 gap-5">
          <Form.Item label="ملاحظات " className="col-span-2">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="المرفقات"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="col-span-2"
          >
            <Upload action="/upload.do" listType="picture-card">
              <button style={{ border: 0, background: "none" }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>تحميل</div>
              </button>
            </Upload>
          </Form.Item>
        </div>
        <div className="xl:px-10 grid grid-cols-2 gap-14 justify-center">
          <div className="flex flex-nowrap w-full gap-10 justify-between border-b-[1px] border-b-primary">
            <p className="font-bold">اجمالي:</p>
            <span className="font-bold">70</span>
            <strong>فقط</strong>
          </div>
        </div>

        {/* جدول التوجيه المحاسبي */}
        <AddAccountingGuidance />
        <div className="flex justify-end items-center mt-5">
          <Button
            className="h-10 w-[200px] flex items-center font-bold justify-center"
            type="primary"
          >
            حفظ
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default ApostateCashing;
