import { Tabs, TabsProps } from "antd";
import CustomerCodes from "./CustomerInstallmentManagement";
const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "العملاء ",
    children: <CustomerCodes />,
  },
  {
    key: "2",
    label: "البيانات الاساسيه",
    children: <>2</>,
  },
];

function TabsCustomerInstallmentManagement() {
  return (
    <div>
      <Tabs onChange={onChange} type="card" items={items} />
    </div>
  );
}

export default TabsCustomerInstallmentManagement;
