import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalOperationTerms";
import TableFirstTime from "./TableOperationTerms";

function IndexOperationTerms() {
  return (
    <>
      <Heading name={"بنود العمليه"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexOperationTerms;
