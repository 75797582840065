import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { LevelKeysProps, MenuItem } from "./Type";

import {
  FcHome,
  FcAutomatic,
  FcDebt,
  FcCurrencyExchange,
  FcLibrary,
  FcDonate,
  FcMoneyTransfer,
  FcSupport,
  FcSelfie,
  FcNeutralTrading,
  FcSelfServiceKiosk,
  FcConferenceCall,
  FcTemplate,
  FcDepartment,
  FcSteam,
  FcTreeStructure,
} from "react-icons/fc";
import { Link } from "react-router-dom";
import UserCodesModle from "modules/SystemSettings/UserCodes/UserCodes";

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

function MenuComponents() {
  // لفتح موديل الخاص ب اكود المستخدمين
  const [UserCodes, setUserCodes] = useState(false);
  const showModal = (e) => {
    e.preventDefault(); // لمنع الانتقال إلى رابط آخر
    setUserCodes(true);
  };
  const [stateOpenKeys, setStateOpenKeys] = useState(["1", "1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const items: MenuItem[] = [
    {
      key: "1",
      icon: <FcHome size={25} />,
      label: <Link to={"/"}>Home</Link>,
    },
    {
      key: "2",
      icon: <FcAutomatic size={25} />,
      label: "إعدادت النظام",
      children: [
        {
          key: "21",
          label: <Link to={"/Settings/CompanyData"}>بيانات الشركه</Link>,
        },
        {
          key: "22",
          label: (
            <Link to={"#"} onClick={showModal}>
              اكواد المستخدامين
            </Link>
          ),
        },
        {
          key: "23",
          label: <Link to={"/Settings/UserPermissions"}>صلاحيات المستخدم</Link>,
        },
        {
          key: "24",
          label: (
            <Link to={"/Settings/ChangeThePassword"}>تغير كلمه المرور</Link>
          ),
        },
        {
          key: "25",
          label: <Link to={"/Settings/CompanyNews"}>اخبار الشركه</Link>,
        },
        { key: "26", label: <Link to={"#"}>اللوائح التنظميه</Link> },
        {
          key: "27",
          label: <Link to={"/Settings/Settings"}>الاعدادات</Link>,
        },
      ],
    },
    {
      key: "3",
      icon: <FcDebt size={25} />,
      label: <Link to={"#"}>الحسابات</Link>,
      children: [
        { key: "31", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "32",
          label: <Link to={"/Accounts/AccountsTree"}>شجره الحسابات</Link>,
        },
        {
          key: "33",
          label: (
            <Link to={"/Accounts/OpeningRestrictions"}>القيود الافتتاحيه</Link>
          ),
        },
        {
          key: "34",
          label: (
            <Link to={"/Accounts/DailyRestrictions"}>القيود اليوميه GL</Link>
          ),
        },
        { key: "35", label: <Link to={"#"}>التقارير</Link> },
        {
          key: "36",
          label: <Link to={"/Accounts/AccountsSettings"}>الاعدادت</Link>,
        },
      ],
    },
    {
      key: "4",
      icon: <FcCurrencyExchange size={25} />,
      label: <Link to={"#"}>الحركه الماليه</Link>,
      children: [
        { key: "41", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "42",
          label: (
            <Link to={"/FinancialMovement/CurrencyRate"}>اسعار العملات</Link>
          ),
        },
        {
          key: "43",
          label: (
            <Link to={"/FinancialMovement/ReceiptDocument"}>مستند قبض</Link>
          ),
        },
        {
          key: "44",
          label: (
            <Link to={"/FinancialMovement/CashingDocument"}>مستند صرف</Link>
          ),
        },
        {
          key: "45",
          label: <Link to={"/FinancialMovement/MoveChecks"}>حركه الشيكات</Link>,
        },
        {
          key: "46",
          label: <Link to={"/FinancialMovement/Predecessor"}>السلف</Link>,
        },
        {
          key: "47",
          label: <Link to={"/FinancialMovement/TheCovenant"}>العهد</Link>,
        },
        {
          key: "48",
          label: (
            <Link to={"/FinancialMovement/DailyRestrictions"}>
              القيود اليوميه GL
            </Link>
          ),
        },
        { key: "49", label: <Link to={"#"}>التقارير</Link> },
        {
          key: "50",
          label: (
            <Link to={"/FinancialMovement/FinancialMovementSettings"}>
              الاعدادت
            </Link>
          ),
        },
      ],
    },
    {
      key: "5",
      icon: <FcLibrary size={25} />,
      label: <Link to={"#"}>المخازن</Link>,
      children: [
        { key: "51", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "52",
          label: <Link to={"/Stores/MovementOfItems"}>اكواد الاصناف</Link>,
        },
        {
          key: "53",
          label: <Link to={"/Stores/AdditionStep"}>حركه الاضافه </Link>,
        },
        {
          key: "54",
          label: <Link to={"/Stores/ExchangeMovement"}>حركه الصرف</Link>,
        },
        {
          key: "55",
          label: (
            <Link to={"/Stores/PhysicalInventoryMovement"}>
              حركه الجرد الفعلى
            </Link>
          ),
        },
        {
          key: "56",
          label: (
            <Link to={"/Stores/StoreTransferMovement"}>
              حركه التحويل المخزنى
            </Link>
          ),
        },
        { key: "57", label: <Link to={"#"}>طباعه الباركود</Link> },
        { key: "58", label: <Link to={"#"}>التقارير</Link> },
        {
          key: "59",
          label: <Link to={"/Stores/StoresSettings"}>الاعدادت</Link>,
        },
      ],
    },
    {
      key: "6",
      icon: <FcDonate size={25} />,
      label: <Link to={"#"}>المشتريات</Link>,
      children: [
        { key: "51", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "62",
          label: <Link to={"/Purchases/SupplierCodes"}>الموردين</Link>,
        },
        { key: "63", label: <Link to={"/Purchases/BuyOrder"}>طلب شراء</Link> },
        { key: "64", label: <Link to={"#"}>مقارنه اسعار</Link> },
        {
          key: "65",
          label: <Link to={"/Purchases/PurchaseOrder"}>امر شراء</Link>,
        },
        {
          key: "66",
          label: <Link to={"/Purchases/PurchaseInvoice"}>فاتوره مشتريات</Link>,
        },
        {
          key: "67",
          label: <Link to={"/Purchases/ReturnedPurchases"}>مرتد مشتريات</Link>,
        },
        { key: "68", label: <Link to={"#"}>التقارير</Link> },
        { key: "69", label: <Link to={"/Purchases/Settings"}>الاعدادت</Link> },
      ],
    },
    {
      key: "7",
      icon: <FcMoneyTransfer size={25} />,
      label: <Link to={"#"}>المبيعات</Link>,
      children: [
        { key: "71", label: <Link to={"#"}>إحصائيات</Link> },
        { key: "72", label: <Link to={"/Sales/CustomersCode"}>العملاء</Link> },
        { key: "73", label: <Link to={"/Sales/OfferPrice"}>عرض سعر</Link> },
        {
          key: "74",
          label: <Link to={"/Sales/OrderedSupply"}>امر توريد</Link>,
        },
        { key: "75", label: <Link to={"/Sales/Sales"}>مبيعات</Link> },
        {
          key: "76",
          label: <Link to={"/Sales/SalesBouncer"}>مرتد المبيعات</Link>,
        },
        { key: "77", label: <Link to={"#"}>التقارير</Link> },
        { key: "78", label: <Link to={"/Sales/Settings"}>الاعدادت</Link> },
      ],
    },
    {
      key: "15",
      icon: <FcTreeStructure size={25} />,
      label: <Link to={"#"}>متابعه الطلبيات</Link>,
      children: [
        { key: "151", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "152",
          label: <Link to={"/FollowUpOnOrders/Customer"}>العملاء</Link>,
        },
        {
          key: "153",
          label: (
            <Link to={"/FollowUpOnOrders/InvoiceDeliveries"}>
              تسليمات الفواتير
            </Link>
          ),
        },
        {
          key: "154",
          label: <Link to={"/FollowUpOnOrders/Settings"}>اعدادات</Link>,
        },
      ],
    },
    {
      key: "8",
      icon: <FcSupport size={25} />,
      label: <Link to={"#"}>الانتاج</Link>,
      children: [
        { key: "81", label: <Link to={"#"}>إحصائيات</Link> },
        { key: "82", label: <Link to={"/Production/WorkOrder"}>امر شغل</Link> },
        {
          key: "83",
          label: (
            <Link to={"/Production/ReceivingTheWorkOrder"}>
              استلام أمر الشغل
            </Link>
          ),
        },
        { key: "84", label: <Link to={"/Production/Examination"}>الفحص</Link> },
        {
          key: "85",
          label: (
            <Link to={"/Production/ReceiveTheFinishedProduct"}>
              استلام المنتج التام
            </Link>
          ),
        },
        { key: "86", label: <Link to={"#"}>التقارير</Link> },
        { key: "87", label: <Link to={"/Production/Settings"}>الاعدادت</Link> },
      ],
    },
    {
      key: "9",
      icon: <FcSelfie size={25} />,
      label: <Link to={"#"}>إداره المشروعات</Link>,
      children: [
        { key: "91", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "92",
          label: <Link to={"/ProjectsManagement/Projects"}>المشروعات</Link>,
        },
        {
          key: "93",
          label: (
            <Link to={"/ProjectsManagement/ProjectsManagement"}>
              اداره المشروعات
            </Link>
          ),
        },
        { key: "94", label: <Link to={"#"}>الجدول الزمنى</Link> },
        {
          key: "95",
          label: <Link to={"/ProjectsManagement/Mission"}>المهام</Link>,
        },
        {
          key: "96",
          label: <Link to={"/ProjectsManagement/Settings"}>الاعدادت</Link>,
        },
      ],
    },
    {
      key: "10",
      icon: <FcNeutralTrading size={25} />,
      label: <Link to={"#"}>المقاولات</Link>,
      children: [
        { key: "101", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "102",
          label: <Link to={"/Contracting/Contracting"}>المقاولين</Link>,
        },
        {
          key: "103",
          label: (
            <Link to={"/Contracting/AbstractCodes"}>اكواد المستخلصات</Link>
          ),
        },
        {
          key: "104",
          label: <Link to={"/Contracting/OperationTerms"}>بنود العمليه</Link>,
        },
        {
          key: "105",
          label: (
            <Link to={"/Contracting/ContractorExtract"}>مستخلص مقاول</Link>
          ),
        },
        { key: "106", label: <Link to={"#"}>مستخلص عميل</Link> },
        { key: "107", label: <Link to={"#"}>التقارير</Link> },
        {
          key: "108",
          label: <Link to={"/Contracting/Settings"}>الاعدادات</Link>,
        },
      ],
    },
    {
      key: "11",
      icon: <FcSelfServiceKiosk size={25} />,
      label: <Link to={"#"}>إداره الاقساط</Link>,
      children: [
        { key: "111", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "112",
          label: <Link to={"/InstallmentManagement/Customer"}>العملاء</Link>,
        },
        {
          key: "113",
          label: (
            <Link to={"/InstallmentManagement/AddData"}>اضافه بيانات </Link>
          ),
        },
        {
          key: "114",
          label: (
            <Link to={"/InstallmentManagement/Installments"}>الاقساط</Link>
          ),
        },
        { key: "115", label: <Link to={"#"}>التقارير</Link> },
        { key: "116", label: <Link to={"#"}>الاعدادات</Link> },
      ],
    },
    {
      key: "12",
      icon: <FcConferenceCall size={25} />,
      label: <Link to={"#"}>CRM</Link>,
      children: [
        { key: "121", label: <Link to={"#"}>إحصائيات</Link> },
        { key: "122", label: <Link to={"CRM/CustomerCRM"}>العملاء</Link> },
        { key: "123", label: <Link to={"#"}>رفع بيانات مجمعه</Link> },
        {
          key: "124",
          label: <Link to={"CRM/CustomerFollowUp"}>متابعه العملاء</Link>,
        },
        { key: "125", label: <Link to={"CRM/InitialRequest"}>طلب مبدئى</Link> },
        { key: "126", label: <Link to={"CRM/SellOrder"}>امر بيع</Link> },
        { key: "127", label: <Link to={"#"}>التقارير</Link> },
        { key: "128", label: <Link to={"#"}>الاعدادت</Link> },
      ],
    },
    {
      key: "13",
      icon: <FcTemplate size={25} />,
      label: <Link to={"#"}>بوابه العملاء</Link>,
      children: [
        { key: "131", label: <Link to={"#"}>إحصائيات</Link> },
        { key: "132", label: <Link to={"#"}>نموذج التسجيل</Link> },
        { key: "133", label: <Link to={"#"}>طلبات العملاء</Link> },
        { key: "134", label: <Link to={"#"}>الاعدادات</Link> },
      ],
    },
    {
      key: "14",
      icon: <FcDepartment size={25} />,
      label: <Link to={"#"}>بوابه الموردين</Link>,
      children: [
        { key: "141", label: <Link to={"#"}>إحصائيات</Link> },
        { key: "142", label: <Link to={"#"}>نموذج التسجيل </Link> },
        { key: "143", label: <Link to={"#"}>طلبات المورد</Link> },
        { key: "144", label: <Link to={"#"}>الاعدادات</Link> },
      ],
    },
    {
      key: "16",
      icon: <FcSteam size={25} />,
      label: <Link to={"#"}>الصيانه</Link>,
      children: [
        { key: "161", label: <Link to={"#"}>إحصائيات</Link> },
        {
          key: "162",
          label: <Link to={"Maintenance/Customers"}>عملاء صيانه</Link>,
        },
        {
          key: "163",
          label: <Link to={"Maintenance/MaintenanceOrder"}>امر صيانه</Link>,
        },
        {
          key: "164",
          label: (
            <Link to={"Maintenance/MaintenanceInvoice"}>فاتوره صيانه</Link>
          ),
        },
        {
          key: "165",
          label: (
            <Link to={"Maintenance/MaintenanceApostate"}>مرتد صياينه</Link>
          ),
        },
        { key: "166", label: <Link to={"#"}>تقارير</Link> },
        { key: "167", label: <Link to={"#"}>الاعدادات</Link> },
      ],
    },
  ];
  const levelKeys = getLevelKeys(items as LevelKeysProps[]);

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        // style={{ width: 256 }}
        items={items}
      />
      <UserCodesModle UserCodes={UserCodes} setUserCodes={setUserCodes} />
    </>
  );
}

export default MenuComponents;
