import { Button, Modal } from "antd";
import { useState } from "react";
import TabsCustomerCodes from "./TabsCustomerInstallmentManagement";
import Heading from "components/Heading/Heading";
import BasicButton from "components/BasicButton/BasicButton";

function ModalCustomerInstallmentManagement() {
  const [openModalCustomerCodes, setOpenModalCustomerCodes] = useState(false);

  return (
    <>
      <Heading name={"العملاء"} />
      <BasicButton>
        <Button
          className="w-[150px] h-8"
          type="primary"
          onClick={() => setOpenModalCustomerCodes(true)}
        >
          اضافه
        </Button>
      </BasicButton>
      <Modal
        title="اضافه اكود العملاء"
        style={{ top: 20 }}
        open={openModalCustomerCodes}
        onOk={() => setOpenModalCustomerCodes(false)}
        onCancel={() => setOpenModalCustomerCodes(false)}
        width={1200}
        footer={false}
      >
        <TabsCustomerCodes />
      </Modal>
    </>
  );
}

export default ModalCustomerInstallmentManagement;
