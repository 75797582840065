import ModalOpeningRestrictions from "./Modal/ModalReceiptDocument";
import TableOpeningRestrictions from "./TableReceiptDocument";

function IndexReceiptDocument() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexReceiptDocument;
