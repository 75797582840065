import { Button, Modal, Tabs, TabsProps } from "antd";
import { useState } from "react";
import Heading from "components/Heading/Heading";
import BasicInformation from "./BasicInformation/BasicInformation";
import ItemSuppliers from "./ItemSuppliers/ItemSuppliers";
import Barcode from "./Barcode/Barcode";
import ControlAndMonitoring from "./ControlAndMonitoring/ControlAndMonitoring";
import OtherData from "./OtherData/OtherData";
import AdditionalChipsAndModules from "./AdditionalChipsAndModules/AdditionalChipsAndModules";
import ItemPictures from "./ItemPictures/ItemPictures";
import BasicButton from "components/BasicButton/BasicButton";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "البيانات الاساسيه",
    children: <BasicInformation />,
  },
  {
    key: "2",
    label: "شرائح الاسعار ووحدات اضافيه",
    children: <AdditionalChipsAndModules />,
  },
  {
    key: "3",
    label: "موردى الصنف ",
    children: <ItemSuppliers />,
  },
  {
    key: "4",
    label: "التحكم والمراقبه",
    children: <ControlAndMonitoring />,
  },
  {
    key: "5",
    label: "الباركود",
    children: <Barcode />,
  },
  {
    key: "6",
    label: "البيانات الاخرى",
    children: <OtherData />,
  },
  {
    key: "7",
    label: "صور الصنف",
    children: <ItemPictures />,
  },
];

function ModalMovementOfItems() {
  const [openModalOpeningRestrictions, setOpenModalOpeningRestrictions] =
    useState(false);

  return (
    <>
      <Heading name={"الاصناف"} />
      <BasicButton>
        <Button
          className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800 w-[150px]"
          type="primary"
          onClick={() => setOpenModalOpeningRestrictions(true)}
        >
          اضافه
        </Button>
      </BasicButton>
      <Modal
        title="اضافه صنف جديد"
        style={{ top: 20 }}
        // style={{ top: 20 }}
        open={openModalOpeningRestrictions}
        onOk={() => setOpenModalOpeningRestrictions(false)}
        onCancel={() => setOpenModalOpeningRestrictions(false)}
        width={1200}
        footer={false}
      >
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          type="card"
          items={items}
        />
      </Modal>
    </>
  );
}

export default ModalMovementOfItems;
