import {
  Form,
  GetProp,
  Input,
  DatePicker,
  Upload,
  UploadFile,
  UploadProps,
  Button,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
// import ImgCrop from "antd-img-crop";
type SizeType = Parameters<typeof Form>[0]["size"];
const { RangePicker } = DatePicker;

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

function CompanyNews() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <Form
      labelCol={{ span: 4 }}
      layout="horizontal"
      initialValues={{ size: componentSize }}
      onValuesChange={onFormLayoutChange}
      size={componentSize as SizeType}
      style={{ maxWidth: 600 }}
    >
      <Form.Item label="عنوان الخبر" className="mb-3">
        <Input />
      </Form.Item>
      <Form.Item label=" الخبر" className="mb-3">
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="صوره الخبر" className="mb-3">
        <Upload
          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
        >
          {fileList.length < 5 && "+ Upload"}
        </Upload>
      </Form.Item>
      <Form.Item label="فديو الخبر" className="mb-3">
        <Input />
      </Form.Item>
      <Form.Item label="من    الي" className="mb-3">
        <RangePicker className="w-full" />
      </Form.Item>
      <div className="text-end">
        <Button className="w-[150px]" type="primary">
          اضافه
        </Button>
      </div>
    </Form>
  );
}

export default CompanyNews;
