import ModalMovementOfItems from "./Modal/ModalMovementOfItems";
import TableMovementOfItems from "./TableMovementOfItems";

function IndexMovementOfItems() {
  return (
    <>
      <ModalMovementOfItems />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableMovementOfItems />
    </>
  );
}

export default IndexMovementOfItems;
