import ModalOpeningRestrictions from "./Modal/ModalTheCovenant";
import TableOpeningRestrictions from "./TableTheCovenant";

function IndexTheCovenant() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexTheCovenant;
