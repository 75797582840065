import { Tabs, TabsProps } from "antd";
import Heading from "components/Heading/Heading";
import IndexFirstTime from "./FirstTime/IndexFirstTime";
import IndexAddRequest from "./AddRequest/IndexAddRequest";
import IndexAddition from "./Addition/IndexAddition";
import IndexApostateCashing from "./ApostateCashing/IndexApostateCashing";
import IndexSampleAndGiftsIncoming from "./SampleAndGiftsIncoming/IndexSampleAndGiftsIncoming";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "أول المده",
    children: <IndexFirstTime />,
  },
  {
    key: "2",
    label: "طلب اضافه ",
    children: <IndexAddRequest />,
  },
  {
    key: "3",
    label: "اضافه",
    children: <IndexAddition />,
  },
  {
    key: "4",
    label: "مرتد صرف",
    children: <IndexApostateCashing />,
  },
  {
    key: "5",
    label: "عينات وهدايا وارده",
    children: <IndexSampleAndGiftsIncoming />,
  },
];

function AdditionStep() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"حركه الاضافه"} />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        type="card"
        items={items}
      />
    </>
  );
}

export default AdditionStep;
