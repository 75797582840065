import ModalFirstTime from "./Modal/ModalCashing";
import TableFirstTime from "./TableCashing";

function IndexCashing() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexCashing;
