import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalPhysicalInventoryMovement";
import TableFirstTime from "./TablePhysicalInventoryMovement";

function IndexPhysicalInventoryMovement() {
  return (
    <>
      <Heading name={"حركه الجرد الفعلي"} />
      <div className="mb-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexPhysicalInventoryMovement;
