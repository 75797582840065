import Heading from "components/Heading/Heading";
import ModalDailyRestrictions from "./Modal/ModalDailyRestrictions";
import TableDailyRestrictions from "./TableDailyRestrictions";

function IndexDailyRestrictions() {
  return (
    <>
      <Heading name={"القيود اليوميه GL"} />
      <ModalDailyRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableDailyRestrictions />
    </>
  );
}

export default IndexDailyRestrictions;
