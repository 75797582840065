import Sider from "antd/es/layout/Sider";
import LogoProsoft from "assets/logo.svg";
import MenuComponents from "./MenuComponents";

function SiderComponents() {
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
      }}
      width={230}
    >
      <div className="h-[56px] m-[16px] bg-[rgb(255,255,255)] rounded-md">
        {/* <Image src={LogoProsoft} className="rounded-md"  /> */}
        <img
          src={LogoProsoft}
          className="rounded-md w-full h-full"
          alt="ProSoft"
        />
      </div>
      <MenuComponents />
    </Sider>
  );
}

export default SiderComponents;
