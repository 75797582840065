import React from "react";

function Heading({ name }) {
  return (
    <div className="my-5">
      <h1 className="font-bold text-[26px]">{name}</h1>
    </div>
  );
}

export default Heading;
