import { Form, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useState } from "react";

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

function GeneralSettings() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      className="grid grid-cols-2 mt-8"
      layout="horizontal"
      initialValues={{ size: componentSize }}
      onValuesChange={onFormLayoutChange}
      size={componentSize as SizeType}
      style={{ maxWidth: 1000 }}
    >
      <Form.Item label="حركه الخزينه">
        <Select>
          <Select.Option value="demo">Demo</Select.Option>
          <Select.Option value="demo">Demo</Select.Option>
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="حركه البنك">
        <Select>
          <Select.Option value="demo">Demo</Select.Option>
          <Select.Option value="demo">Demo</Select.Option>
          <Select.Option value="demo">Demo</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="الحساب المرتبط للسلف ">
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={onChange}
          onSearch={onSearch}
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "tom",
              label: "Tom",
            },
          ]}
        />
      </Form.Item>
      <Form.Item label="الحساب المرتبط للعهد ">
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="label"
          onChange={onChange}
          onSearch={onSearch}
          options={[
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "tom",
              label: "Tom",
            },
          ]}
        />
      </Form.Item>
    </Form>
  );
}

export default GeneralSettings;
