import { Tabs, TabsProps } from "antd";
import TableCountryCodes from "./TableCountryCodes";
import TableCountryCodesTow from "./TableCityCodesTow";
import Heading from "components/Heading/Heading";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "اكواد الدول",
    children: <TableCountryCodes />,
  },
  {
    key: "2",
    label: "اكواد البلدان",
    children: <TableCountryCodesTow />,
  },
];

function Settings() {
  return (
    <div>
      <Heading name={"اعدادات النظام"} />
      <Tabs onChange={onChange} type="card" items={items} />
    </div>
  );
}

export default Settings;
