import ModalFirstTime from "./Modal/ModalSamplesAndGiftsIssued";
import TableFirstTime from "./TableSamplesAndGiftsIssued";

function IndexSamplesAndGiftsIssued() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexSamplesAndGiftsIssued;
