import { useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import { Button, Form, Input, Select, Space, Table, DatePicker } from "antd";
import type {
  FilterDropdownProps,
  TableRowSelection,
} from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { DataTypePowersReport } from "./type";

// خاص البتاريخ من والي
const { RangePicker } = DatePicker;
type DataIndex = keyof DataTypePowersReport;

// form
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values: any) => {
  console.log(values);
};

function PowersReport() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const data: DataTypePowersReport[] = [
    {
      key: "1",
      UserCode: 210203001,
      name: "alaa",
      job: "Front-end",
      company: "ProSoft",
      date: "2024/06/14",
      descriptor: "صباح الخيرات",
    },
  ];

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<DataTypePowersReport> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<DataTypePowersReport> = [
    {
      title: "كود المستخدم",
      dataIndex: "UserCode",
      key: "UserCode",
      width: 200,
      ...getColumnSearchProps("UserCode"),
      sorter: (a, b) => a.UserCode - b.UserCode,
    },
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      width: 200,
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "الوظيفة",
      dataIndex: "job",
      key: "job",
      width: 150,
      ...getColumnSearchProps("job"),
      sorter: (a, b) => a.job.length - b.job.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "الشركة",
      dataIndex: "company",
      key: "company",
      width: 150,
    },
    {
      title: "التاريخ",
      dataIndex: "date",
      key: "date",
      width: 150,
    },
    {
      title: "الوصف",
      dataIndex: "descriptor",
      key: "descriptor",
      width: 250,
    },
  ];

  const rowSelection: TableRowSelection<DataTypePowersReport> = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const [checkStrictly, setCheckStrictly] = useState(false);

  return (
    <div>
      <Form
        {...layout}
        name="nest-messages"
        className="lg:pe-16 grid grid-cols-1 lg:grid-cols-2 px-5"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          label="اسم المستخدام"
          name="Username"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <Select>
            <Select.Option value="1">1</Select.Option>
            <Select.Option value="2">2</Select.Option>
            <Select.Option value="3">3</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="الوظيفه"
          name="job"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <Select>
            <Select.Option value="1">1</Select.Option>
            <Select.Option value="2">2</Select.Option>
            <Select.Option value="3">3</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="الفتره"
          name="Period"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <RangePicker
            className="w-full"
            placeholder={["من تاريخ", "الي تاريخ"]}
          />
        </Form.Item>
        <div className="col-span-2 flex justify-center items-center my-5">
          <Button type="primary" className="w-[200px] font-bold">
            تاكيد
          </Button>
        </div>
      </Form>
      <Table
        columns={columns}
        pagination={false}
        scroll={{ y: 400, x: 1000 }}
        dataSource={data}
        bordered
        rowSelection={{ ...rowSelection, checkStrictly }}
      />
    </div>
  );
}

export default PowersReport;
