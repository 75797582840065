import ModalOpeningRestrictions from "./Modal/ModalIssuedChecks";
import TableOpeningRestrictions from "./TableIssuedChecks";

function IndexIssuedChecks() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexIssuedChecks;
