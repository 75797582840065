import excel from "../../assets/excel.svg";
import React, { useState } from "react";

interface ButtonProps {
  children: JSX.Element;
}

function BasicButton({ children }: ButtonProps) {
  return (
    <div className="text-start gap-x-2 gap-y-1 flex flex-wrap items-center">
      {children}
      <button
        type="button"
        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
      >
        بيانات اليوم الحالي
      </button>
      <button
        type="button"
        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
      >
        بيانات الشهر الحالي
      </button>
      <button
        type="button"
        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
      >
        بيانات السنه الحاليه
      </button>
      <button
        type="button"
        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
      >
        كل البيانات
      </button>
      <button
        type="button"
        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 h-[32px] text-center  dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
      >
        رفع مجمع
      </button>
      <img className="cursor-pointer" src={excel} alt="excel" />
    </div>
  );
}

export default BasicButton;
