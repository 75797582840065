import { Button, Checkbox, CheckboxProps, Upload, UploadProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";

const onChange: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const props: UploadProps = {
  action: "//jsonplaceholder.typicode.com/posts/",
  listType: "picture",
  previewFile(file) {
    console.log("Your upload file:", file);
    // Your process logic. Here we just mock to the same file
    return fetch("https://next.json-generator.com/api/json/get/4ytyBoLK8", {
      method: "POST",
      body: file,
    })
      .then((res) => res.json())
      .then(({ thumbnail }) => thumbnail);
  },
};

function LastDataEntry() {
  return (
    <div className="flex flex-col gap-7 mt-5">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        <div className="flex flex-col gap-5">
          <div className="flex flex-nowrap items-center gap-4">
            <label htmlFor="YourFeedback">ملاحظاتك</label>
            <TextArea id="YourFeedback" rows={4} placeholder="اكتب ملاحظاتك" />
          </div>
          <div className="">
            <Upload
              className="grid grid-cols-2 items-center justify-between"
              {...props}
            >
              <Button icon={<UploadOutlined />}>تحميل مرفقات</Button>
            </Upload>
          </div>
          <Checkbox className="col-span-2" onChange={onChange}>
            نقدي
          </Checkbox>
        </div>
        <div className="xl:px-10 grid gap-5">
          <div className="flex flex-nowrap w-full gap-10 justify-between border-b-[1px] border-b-primary">
            <p className="font-bold">اجمالي القيمه :</p>
            <span className="font-bold">100%</span>
          </div>
          <div className="flex flex-nowrap w-full gap-10 justify-between border-b-[1px] border-b-primary">
            <p className="font-bold">اجمالي الخصم :</p>
            <span className="font-bold">50%</span>
            <span className="font-bold">900$</span>
          </div>
          <div className="flex flex-nowrap w-full gap-10 justify-between border-b-[1px] border-b-primary">
            <p className="font-bold">اجمالي ضريبه الخصم :</p>
            <span className="font-bold">100%</span>
          </div>
          <div className="flex flex-nowrap w-full gap-10 justify-between border-b-[1px] border-b-primary">
            <p className="font-bold">اجمالي الضريبه :</p>
            <span className="font-bold">100%</span>
          </div>
          <div className="flex flex-nowrap w-full gap-10 justify-between border-b-[1px] border-b-primary">
            <p className="font-bold">اجمالي الفاتوره :</p>
            <span className="font-bold">100%</span>
          </div>
        </div>
      </div>

      <div className="flex justify-end items-center">
        <Button
          className="h-10 w-[200px] flex items-center font-bold justify-center"
          type="primary"
        >
          حفظ
        </Button>
      </div>
    </div>
  );
}

export default LastDataEntry;
