import { Button, Form, Input, InputNumber } from "antd";
import logo from "../../../assets/logo.svg";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

function CompanyData() {
  return (
    <div className="grid grid-cols-4 gap-10">
      <Form
        {...formItemLayout}
        variant="filled"
        className="col-span-4 lg:col-span-3"
      >
        <div className="flex flex-nowrap items-center mb-5">
          <label htmlFor="CompanyName" className="w-[100px]">
            اسم الشركه
          </label>
          <Input id="CompanyName" placeholder="اسم الشركه" />
        </div>
        <div className="grid grid-cols-2 gap-10">
          <div className="flex flex-nowrap items-center">
            <Form.Item
              label="رقم الهاتف"
              name="phoneNumber"
              className="w-full"
              rules={[{ required: true, message: "Please input!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="flex flex-nowrap items-center">
            <Form.Item
              label="رقم الموبايل"
              name="mobileNumber"
              className="w-full"
              rules={[{ required: true, message: "Please input!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <div className="flex flex-nowrap items-center">
            <Form.Item
              label="الرقم الضريبي"
              name="taxNumber"
              className="w-full"
              rules={[{ required: true, message: "Please input!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="flex flex-nowrap items-center">
            <Form.Item
              label="السجل التجارى"
              name="commercialRegister"
              className="w-full"
              rules={[{ required: true, message: "Please input!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <div className="flex flex-nowrap items-center">
            <Form.Item
              label="فاكس"
              name="fax"
              className="w-full"
              rules={[{ required: true, message: "Please input!" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="flex flex-nowrap items-center">
            <Form.Item
              label="البريد الالكترونى"
              name="E-mail"
              className="w-full"
              rules={[{ type: "email" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d865.580085295606!2d31.24171493373486!3d29.797288369085425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sar!2seg!4v1717527224701!5m2!1sar!2seg"
            className="border-0 w-full h-[200px]"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </Form>
      <div className="w-full hidden justify-center lg:flex">
        <img className="w-1/2" src={logo} alt="asdasda" />
      </div>
      <div className="grid justify-center items-center col-span-4">
        <Button
          type="primary"
          className="py-5 px-36 font-bold flex items-center justify-center"
        >
          حفظ
        </Button>
      </div>
    </div>
  );
}

export default CompanyData;
