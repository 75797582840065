import ModalOpeningRestrictions from "./Modal/ModalIncomingCheckBounce";
import TableOpeningRestrictions from "./TableIncomingCheckBounce";

function IndexIncomingCheckBounce() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexIncomingCheckBounce;
