import ModalFirstTime from "./Modal/ModalWorkOrder";
import TableFirstTime from "./TableWorkOrder";

function IndexWorkOrder() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexWorkOrder;
