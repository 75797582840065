import { Tabs, TabsProps } from "antd";
import Heading from "components/Heading/Heading";
import IndexExchangeRequest from "./ExchangeRequest/IndexExchangeRequest";
import IndexCashing from "./Cashing/IndexCashing";
import IndexAddApostate from "./AddApostate/IndexAddApostate";
import IndexSamplesAndGiftsIssued from "./SamplesAndGiftsIssued/IndexSamplesAndGiftsIssued";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "طلب صرف",
    children: <IndexExchangeRequest />,
  },
  {
    key: "2",
    label: "صرف",
    children: <IndexCashing />,
  },
  {
    key: "3",
    label: "مرتد اضافه",
    children: <IndexAddApostate />,
  },
  {
    key: "4",
    label: "تالف",
    children: "",
  },
  {
    key: "5",
    label: "عينات وهدايا صادره",
    children: <IndexSamplesAndGiftsIssued />,
  },
];

function ExchangeMovement() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"حركه الصرف"} />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        type="card"
        items={items}
      />
    </>
  );
}

export default ExchangeMovement;
