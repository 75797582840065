import { Modal } from "antd";
import { useEffect, useState } from "react";
import BasicButton from "components/BasicButton/BasicButton";
import ProjectStages from "./ProjectStages";

function ModalProjectStagess({ setModalProjectStages, ModalProjectStages }) {
  const [openModalOpeningRestrictions, setOpenModalOpeningRestrictions] =
    useState(false);

  useEffect(() => {
    if (ModalProjectStages) {
      setOpenModalOpeningRestrictions(true);
    } else {
      setOpenModalOpeningRestrictions(false);
    }
  }, [ModalProjectStages]);

  return (
    <>
      <Modal
        title="تعيين مهمه"
        style={{ top: 20 }}
        open={openModalOpeningRestrictions}
        onOk={() => setModalProjectStages(false)}
        onCancel={() => setModalProjectStages(false)}
        width={1200}
        footer={false}
        height={500}
      >
        <ProjectStages />
      </Modal>
    </>
  );
}

export default ModalProjectStagess;
