import { Tabs, TabsProps } from "antd";

import Heading from "components/Heading/Heading";
import GeneralSettings from "./GeneralSettings";
import CurrencyCodes from "./CurrencyCodes";
import TypesTaxes from "./TypesTaxes";
import TypesPredecessors from "./TypesPredecessors";
import TypesCovenant from "./TypesCovenant";
import EntityTypeCodes from "./EntityTypeCodes";
import TaxCodes from "./TaxCodes";
import StorageCodesBoxes from "./StorageCodesBoxes";
import BankCodes from "./BankCodes";
import EntitiesCodes from "./EntitiesCodes";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "اعدات عامه",
    children: <GeneralSettings />,
  },
  {
    key: "2",
    label: "اكواد العملات",
    children: <CurrencyCodes />,
  },
  {
    key: "3",
    label: "انواع   الضرائب",
    children: <TypesTaxes />,
  },
  {
    key: "4",
    label: "اكواد الضرائب",
    children: <TaxCodes />,
  },
  {
    key: "5",
    label: "انواع السلف",
    children: <TypesPredecessors />,
  },
  {
    key: "6",
    label: "انواع العهد",
    children: <TypesCovenant />,
  },
  {
    key: "7",
    label: "اكواد الصناديق",
    children: <StorageCodesBoxes />,
  },
  {
    key: "8",
    label: "اكواد البنوك",
    children: <BankCodes />,
  },
  {
    key: "9",
    label: "اكواد انواع الجهات",
    children: <EntityTypeCodes />,
  },
  {
    key: "10",
    label: "اكواد الجهات ",
    children: <EntitiesCodes />,
  },
];

function FinancialMovementSettings() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"اعدادات الحركه الماليه"} />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        type="card"
        items={items}
      />
      ;
    </>
  );
}

export default FinancialMovementSettings;
