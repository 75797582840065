import TableOpeningRestrictions from "./TableIInvoiceDeliveries";

function IndexInvoiceDeliveries() {
  return (
    <>
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexInvoiceDeliveries;
