import { Checkbox, CheckboxProps, Input, Select } from "antd";

const onChangeCheckbox: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
function GeneralSettings() {
  return (
    <>
      <div className="mt-10 grid grid-cols-1 gap-10">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mx-5">
          <div className="grid grid-cols-7 gap-3 font-semibold items-center">
            <label className="col-span-2 text-end" htmlFor="Select_1">
              تسعير المخزون
            </label>
            <Select
              className="col-span-5"
              id="Select_1"
              defaultValue="lucy"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "yiminghe" },
                { value: "disabled", label: "Disabled", disabled: true },
              ]}
            />
          </div>
          <div className="grid grid-cols-7 gap-3 font-semibold items-center">
            <label className="col-span-2 text-end" htmlFor="Select_2">
              السحب على المكشوف
            </label>
            <Select
              className="col-span-5"
              id="Select_2"
              defaultValue="lucy"
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "yiminghe" },
                { value: "disabled", label: "Disabled", disabled: true },
              ]}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 justify-center gap-10">
          <Checkbox onChange={onChangeCheckbox}>استخدام الكميه فقط</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>استخدام السعر صفر </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>تكرار الصنف فى الحركه</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>
            استخدام المخزن على مستوى الصنف
          </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>
            استخدام تواربخ الصلاحية
          </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>
            استخدام نظام المقاسات والالوان
          </Checkbox>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          <div className="grid grid-cols-7 gap-3 font-semibold items-center">
            <Input placeholder="" id="antd1" className="col-span-5" />
            <label htmlFor="antd1" className="col-span-2 text-start">
              معدل الدوران باليوم
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralSettings;
