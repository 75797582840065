import ModalCustomerCodes from "./Modal/ModalCustomerInstallmentManagement";
import TableCustomerCodes from "./TableCustomerInstallmentManagement";

function IndexCustomerInstallmentManagement() {
  return (
    <>
      <ModalCustomerCodes />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableCustomerCodes />
    </>
  );
}

export default IndexCustomerInstallmentManagement;
