import { Tabs, TabsProps } from "antd";

import Heading from "components/Heading/Heading";
import IndexImportChecks from "./ImportChecks/IndexImportChecks";
import IndexIssuedChecks from "./IssuedChecks/IndexIssuedChecks";
import IndexCheckStore from "./CheckStore/IndexCheckStore";
import IndexDepositIncomingCheck from "./DepositIncomingCheck/IndexDepositIncomingCheck";
import IndexCashingOutgoingCheck from "./CashingOutgoingCheck/IndexCashingOutgoingCheck";
import IncomingCheckBounce from "./IncomingCheckBounce/Modal/IncomingCheckBounce";
import IndexIncomingCheckBounce from "./IncomingCheckBounce/IndexIncomingCheckBounce";
import IndexIssuedBouncedChecks from "./IssuedBouncedChecks/IndexIssuedBouncedChecks";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "الشيكات الوراده",
    children: <IndexImportChecks />,
  },
  {
    key: "2",
    label: "الشيكات الصادره",
    children: <IndexIssuedChecks />,
  },
  {
    key: "3",
    label: "محفظه الشيكات",
    children: <IndexCheckStore />,
  },
  {
    key: "4",
    label: "ايداع شيك وارد",
    children: <IndexDepositIncomingCheck />,
  },
  {
    key: "5",
    label: "صرف شيك صادر",
    children: <IndexCashingOutgoingCheck />,
  },
  {
    key: "6",
    label: "مرتد شيكات وارد ",
    children: <IndexIncomingCheckBounce />,
  },
  {
    key: "7",
    label: "مرتد شيكات صادر ",
    children: <IndexIssuedBouncedChecks />,
  },
];

function MoveChecks() {
  return (
    <>
      <div className="mb-7">
        <Heading name={"حركه الشيكات"} />
      </div>
      <Tabs onChange={onChange} type="card" items={items} />;
    </>
  );
}

export default MoveChecks;
