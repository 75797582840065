import ModalFirstTime from "./Modal/ModalAddRequest";
import TableFirstTime from "./TableAddRequest";

function IndexAddRequest() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexAddRequest;
