import { Layout, theme } from "antd";
import { FcBusinessman } from "react-icons/fc";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Link } from "react-router-dom";
const { Header } = Layout;

function HeaderComponents() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Header
      className="p-[20px] flex items-center justify-between"
      style={{ background: colorBgContainer }}
    >
      <h2 className="font-bold text-primary">ProSoft ERP نظام يستحق التجربه</h2>
      <div className="flex flex-nowrap justify-end items-center gap-10">
        <Link to={"/"} className="flex items-center justify-end gap-1">
          <h2 className="text-md font-bold">الاشعارات</h2>
          <MdOutlineNotificationsActive className="text-[35px]" />
        </Link>
        <Link to={"/"} className="flex items-center justify-end gap-1">
          <h2 className="text-md font-bold">Alaa Ahmed</h2>
          <FcBusinessman className="text-[35px]" />
        </Link>
      </div>
    </Header>
  );
}

export default HeaderComponents;
