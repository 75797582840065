import ModalFirstTime from "./Modal/ModalAddApostate";
import TableFirstTime from "./TableAddApostate";

function IndexAddApostate() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexAddApostate;
