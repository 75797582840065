import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalProjectsManagement";
import TableFirstTime from "./TableProjectsManagement";
import { useState } from "react";
import ModalProjectStagess from "./ModalProjectStages/ModalProjectStagess";

function IndexProjectsManagement() {
  const [openModal, setOpenModal] = useState(false);
  const [ModalProjectStages, setModalProjectStages] = useState(false);

  return (
    <>
      <Heading name={"اداره المشروعات"} />
      <div className="mt-5"></div>
      <ModalFirstTime openModal={openModal} setOpenModal={setOpenModal} />
      <ModalProjectStagess
        ModalProjectStages={ModalProjectStages}
        setModalProjectStages={setModalProjectStages}
      />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime
        setOpenModal={setOpenModal}
        setModalProjectStages={setModalProjectStages}
      />
    </>
  );
}

export default IndexProjectsManagement;
