import { Button, Table, TableColumnsType, TableProps } from "antd";
import { useState } from "react";
import { DataType } from "./Type";
type OnChange = NonNullable<TableProps<DataType>["onChange"]>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const data: DataType[] = [
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
  {
    key: "1",
    Number: 55,
    InvoiceNumber: "06/06/2024",
    InvoiceDate: "06/06/2024",
    AgentCode: "200",
    CustomerName: "علاء",
    Project: "مجمع مصانع",
    Status: "جديد",
    Events: ["name1", "name2", "name3", "name4"],
  },
];

function TableDailyRestrictions() {
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const handleChange: OnChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "م",
      dataIndex: "Number",
      key: "Number",
      filters: [
        { text: "Joe", value: "Joe" },
        { text: "Jim", value: "Jim" },
      ],
      filteredValue: filteredInfo.name || null,
      sorter: (a, b) => a.Number - b.Number,
      sortOrder: sortedInfo.columnKey === "Number" ? sortedInfo.order : null,
      ellipsis: true,
      width: 45,
    },
    {
      title: "رقم القيد",
      dataIndex: "AgentCode",
      key: "AgentCode",
      filters: [
        { text: "London", value: "London" },
        { text: "New York", value: "New York" },
      ],
      filteredValue: filteredInfo.AgentCode || null,
      onFilter: (value, record) => record.AgentCode.includes(value as string),
      sorter: (a, b) => a.AgentCode.length - b.AgentCode.length,
      sortOrder: sortedInfo.columnKey === "AgentCode" ? sortedInfo.order : null,
      ellipsis: true,
      width: 100,
    },
    {
      title: "تاريخ القيد",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
      filters: [
        { text: "London", value: "London" },
        { text: "New York", value: "New York" },
      ],
      filteredValue: filteredInfo.InvoiceDate || null,
      onFilter: (value, record) => record.InvoiceDate.includes(value as string),
      sorter: (a, b) => a.InvoiceDate.length - b.InvoiceDate.length,
      sortOrder:
        sortedInfo.columnKey === "InvoiceDate" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
    },
    {
      title: "اجمالي القيد",
      dataIndex: "CustomerName",
      key: "CustomerName",
      filters: [
        { text: "London", value: "London" },
        { text: "New York", value: "New York" },
      ],
      filteredValue: filteredInfo.CustomerName || null,
      onFilter: (value, record) =>
        record.CustomerName.includes(value as string),
      // sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
      sortOrder:
        sortedInfo.columnKey === "CustomerName" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
    },
    {
      title: "جهة الحساب",
      dataIndex: "CustomerName",
      key: "CustomerName",
      filters: [
        { text: "London", value: "London" },
        { text: "New York", value: "New York" },
      ],
      filteredValue: filteredInfo.CustomerName || null,
      onFilter: (value, record) =>
        record.CustomerName.includes(value as string),
      // sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
      sortOrder:
        sortedInfo.columnKey === "CustomerName" ? sortedInfo.order : null,
      ellipsis: true,
      width: 120,
    },
    {
      title: "نوع القيد",
      dataIndex: "Project",
      key: "Project",
      width: 100,
    },
    {
      title: "",
      dataIndex: "Events",
      key: "Events",
      render: (_, record) => (
        <div className="grid grid-cols-2 items-center justify-center gap-2">
          <Button type="primary">طباعه</Button>
          <Button type="primary">تعديل</Button>
          <Button type="primary" danger>
            حذف
          </Button>
        </div>
      ),
      width: 120,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        bordered
        dataSource={data}
        onChange={handleChange}
        scroll={{ x: 1200, y: 350 }}
      />
    </>
  );
}

export default TableDailyRestrictions;
