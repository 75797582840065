import { Button, Modal } from "antd";
import { useState } from "react";
import DailyRestrictions from "./DailyRestrictions";
import BasicButton from "components/BasicButton/BasicButton";
import Heading from "components/Heading/Heading";

function ModalDailyRestrictions() {
  const [
    openModalOpeningDailyRestrictions,
    setOpenModalOpeningDailyRestrictions,
  ] = useState(false);

  return (
    <>
      <Heading name={"القيود اليوميه GL"} />
      <BasicButton>
        <Button
          className="w-[150px] h-8"
          type="primary"
          onClick={() => setOpenModalOpeningDailyRestrictions(true)}
        >
          اضافه
        </Button>
      </BasicButton>

      <Modal
        title="اضافه  قيد يوميه GL"
        style={{ top: 20 }}
        open={openModalOpeningDailyRestrictions}
        onOk={() => setOpenModalOpeningDailyRestrictions(false)}
        onCancel={() => setOpenModalOpeningDailyRestrictions(false)}
        width={1400}
        footer={false}
      >
        <DailyRestrictions />
      </Modal>
    </>
  );
}

export default ModalDailyRestrictions;
