import { Button, Modal } from "antd";
import { useState } from "react";
import CompanyNews from "./CompanyNews";

function ModalCompanyNews() {
  const [openModalCompanyNews, setOpenModalCompanyNews] = useState(false);

  return (
    <>
      <div className="text-start">
        <Button
          className="w-[300px] h-8"
          type="primary"
          onClick={() => setOpenModalCompanyNews(true)}
        >
          اضافه
        </Button>
      </div>
      <Modal
        title="اضافه الاخبار"
        style={{ top: 20 }}
        open={openModalCompanyNews}
        onOk={() => setOpenModalCompanyNews(false)}
        onCancel={() => setOpenModalCompanyNews(false)}
        width={600}
        footer={false}
      >
        <CompanyNews />
      </Modal>
    </>
  );
}

export default ModalCompanyNews;
