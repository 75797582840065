import ModalDailyRestrictions from "./Modal/ModalDailyRestrictions";
import TableDailyRestrictions from "./TableDailyRestrictions";

function IndexDailyRestrictionsGL() {
  return (
    <>
      <ModalDailyRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableDailyRestrictions />
    </>
  );
}

export default IndexDailyRestrictionsGL;
