import ModalOpeningRestrictions from "./Modal/ModalCashingDocument";
import TableOpeningRestrictions from "./TableCashingDocument";

function IndexCashingDocument() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexCashingDocument;
