import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  DatePicker,
  Form,
  Input,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";

type SizeType = Parameters<typeof Form>[0]["size"];
const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const onChangeCheckbox: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

function InvoiceDeliveries() {
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );

  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-1 md:grid-cols-3 gap-10"
      >
        <div className="col-span-2">
          <Form.Item label="تاريخ الحاله">
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item label="الموظف المسئول">
            <Select
              showSearch
              placeholder="الموظف المسئول"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="الموقف النهائئ">
            <Input className="w-full" />
          </Form.Item>
          <Form.Item label="ملاحظات " className="col-span-2">
            <TextArea rows={4} />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 items-start justify-center">
          <Checkbox onChange={onChangeCheckbox}>تحت التجهيز </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>فى الطريق</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>تم التسليم</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>تم التحصيل</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>مرتجع</Checkbox>
        </div>
      </Form>
      <div className="grid justify-end items-center">
        <Button
          type="primary"
          className="py-4 px-20 font-bold flex items-center justify-center"
        >
          حفظ
        </Button>
      </div>
    </>
  );
}

export default InvoiceDeliveries;
