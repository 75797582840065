import ModalOpeningRestrictions from "./Modal/ModalImportChecks";
import TableOpeningRestrictions from "./TableImportChecks";

function IndexImportChecks() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexImportChecks;
