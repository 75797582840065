import React from "react";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import Heading from "components/Heading/Heading";
type FieldType = {
  OldPassword?: string;
  NewPassword?: string;
  ConfirmNewPassword?: string;
};

const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
  console.log("Success:", values);
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
function ChangeThePassword() {
  return (
    <div className="h-[calc(100vh-205px)] w-full flex items-center justify-center flex-col">
      <div className="mb-20">
        <Heading name={"تغير كلمه السر"} />
      </div>
      <Form
        className="border border-solid border-primary rounded-lg p-10 lg:p-20"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: "100", width: 800 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="كلمه السر القديمه"
          name="OldPassword"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<FieldType>
          label="كلمه السر الجديده"
          name="NewPassword"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item<FieldType>
          label="تاكيد كلمه السر"
          name="ConfirmNewPassword"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button className="w-[300px]" type="primary" htmlType="submit">
            حفظ
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangeThePassword;
