import React, { useState } from "react";
import type { InputNumberProps, TableProps } from "antd";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  DatePicker,
  Select,
  Upload,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

function CustomerFollowUp() {
  // form
  const [componentSize, setComponentSize] = useState<SizeType | "default">(
    "default"
  );
  const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    setComponentSize(size);
  };

  return (
    <>
      {/* start data entry  */}
      <Form
        labelCol={{ span: 8 }}
        // wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: componentSize }}
        onValuesChange={onFormLayoutChange}
        size={componentSize as SizeType}
        // style={{ maxWidth: 600 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:md:grid-cols-3 gap-5 mt-10"
      >
        <div className="">
          <Form.Item label="كود">
            <Input className="w-full" />
          </Form.Item>
          <Form.Item label="اسم العميل">
            <Select
              showSearch
              placeholder="اسم العميل"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="العنوان">
            <Input className="w-full" />
          </Form.Item>
          <Form.Item label="رقم الموبيل">
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item label="نوع العميل">
            <Select
              showSearch
              placeholder="نوع العميل"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="رقم القومي">
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item label="طريقه التسجيل">
            <Select
              showSearch
              placeholder="طريقه التسجيل"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="تاريخ المتابعه">
            <DatePicker className="w-full" />
          </Form.Item>
        </div>
        <div className="">
          <Form.Item label="نوع المتابعه">
            <Select
              showSearch
              placeholder="نوع المتابعه"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="مركز التكلفه">
            <Select
              showSearch
              placeholder="مركز التكلفه"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="تاريخ المتابعه القادمه">
            <DatePicker className="w-full" />
          </Form.Item>
        </div>
      </Form>
      {/* end */}
      <div className="mt-3">
        <div className="grid grid-cols-2 gap-5">
          <Form.Item label="ملاحظات ">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="المرفقات"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card">
              <button style={{ border: 0, background: "none" }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>تحميل</div>
              </button>
            </Upload>
          </Form.Item>
        </div>
        <div className="flex justify-end items-center mt-5 gap-10">
          <Button
            className="h-10 w-[200px] flex items-center font-bold justify-center"
            type="primary"
          >
            حفظ
          </Button>
        </div>
      </div>
    </>
  );
}

export default CustomerFollowUp;
