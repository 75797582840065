import ModalOpeningRestrictions from "./Modal/ModalCheckStore";
import TableOpeningRestrictions from "./TableCheckStore";

function IndexCheckStore() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexCheckStore;
