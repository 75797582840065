import { Checkbox, CheckboxProps, Select } from "antd";
import Heading from "components/Heading/Heading";

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const onChangeCheckbox: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
function SalesSettings() {
  return (
    <>
      <Heading name={"اعدادات المبيعات"} />
      <div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mb-10">
          <div className="grid grid-cols-7 gap-3 font-semibold items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              المورد الافتراضى
            </label>
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "110",
                  label: "110",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-3"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 justify-center gap-10">
          <Checkbox onChange={onChangeCheckbox}>
            طباعه فاتوره المبيعات فورا
          </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>استخدام الاضافه اولا</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>
            ترصيد الخصم على مستوى الصنف
          </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>
            استخدام اخر سعر المورد كسعر صنف
          </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>
            تكرار الصنف فلى الحركه
          </Checkbox>
          <Checkbox onChange={onChangeCheckbox}>استخدام السعر صفر</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>اغلاق مسلسل الفواتير</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>اظهار صور الصنف </Checkbox>
        </div>
      </div>
    </>
  );
}

export default SalesSettings;
