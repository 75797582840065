import { Checkbox, CheckboxProps, Select } from "antd";
import Heading from "components/Heading/Heading";

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log("search:", value);
};

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const onChangeCheckbox: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

function AccountsSettings() {
  return (
    <>
      <Heading name={"الاعدادات الحسابات"} />
      <div>
        <div className="flex justify-center gap-10 my-10 flex-wrap font-semibold">
          <Checkbox onChange={onChangeCheckbox}>الربط بالحسابات</Checkbox>
          <Checkbox onChange={onChangeCheckbox}>الربط بمراكز التكلفه</Checkbox>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              ضريبه القيمه المضافه
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "110",
                  label: "110",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب الاضافه للمخزون
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              ضريبه الخصم
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب الصرف للمخزون
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              الخصم المكتسب
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب مرتد الصرف للمخزون
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              الخصم المسموح به
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب العينات الوارده
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              صافى الربح
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب العينات الصادره
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              صلفى الخساره
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب تالف المخزون
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-6 gap-3 items-center">
            <label className="col-span-2 text-start" htmlFor="ValueAddedTax">
              حساب الارباح والخسائر المرحله
            </label>
            <Select
              id="ValueAddedTax"
              showSearch
              placeholder="كود"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
            <Select
              className="col-span-2"
              id="ValueAddedTax"
              showSearch
              placeholder="اسم الحساب"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountsSettings;
