import ModalOpeningRestrictions from "./Modal/ModalCashingOutgoingCheck";
import TableOpeningRestrictions from "./TableCashingOutgoingCheck";

function IndexCashingOutgoingCheck() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexCashingOutgoingCheck;
