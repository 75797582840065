import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalStoreTransferMovement";
import TableFirstTime from "./TableStoreTransferMovement";

function IndexStoreTransferMovement() {
  return (
    <>
      <Heading name={"حركه التحويل المخزني"} />
      <div className="mb-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexStoreTransferMovement;
