import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalInstallments";
import TableFirstTime from "./TableInstallments";

function IndexInstallments() {
  return (
    <>
      <Heading name={"الاقساط"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexInstallments;
