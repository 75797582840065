import BasicButton from "components/BasicButton/BasicButton";
import TableCustomerCodes from "./TableCustomer";
import Heading from "components/Heading/Heading";

function IndexCustomer() {
  return (
    <>
      <Heading name={"العملاء"} />
      <BasicButton>{undefined}</BasicButton>
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableCustomerCodes />
    </>
  );
}

export default IndexCustomer;
