import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalMission";
import TableFirstTime from "./TableMission";

function IndexMission() {
  return (
    <>
      <Heading name={"المهام"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexMission;
