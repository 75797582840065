import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalProjects";
import TableFirstTime from "./TableProjects";

function IndexProjects() {
  return (
    <>
      <Heading name={"المشروعات"} />
      <div className="mt-5"></div>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexProjects;
