import ModalOpeningRestrictions from "./Modal/ModalPredecessor";
import TableOpeningRestrictions from "./TableIPredecessor";

function IndexPredecessor() {
  return (
    <>
      <ModalOpeningRestrictions />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableOpeningRestrictions />
    </>
  );
}

export default IndexPredecessor;
