import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/RootLayout/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "modules/Home/Home";
import CompanyData from "modules/SystemSettings/CompanyData/CompanyData";
import AccountsTree from "modules/Accounts/AccountsTree/AccountsTree";
import Sales from "modules/Sales/Sales/Sales";
import ChangeThePassword from "modules/SystemSettings/ChangeThePassword/ChangeThePassword";
import TabsUserPermissions from "modules/SystemSettings/UserPermissions/TabsUserPermissions";
import BuyOrder from "modules/Purchases/BuyOrder/BuyOrder";
import PurchaseOrder from "modules/Purchases/PurchaseOrder/PurchaseOrder";
import ReturnedPurchases from "modules/Purchases/ReturnedPurchases/ReturnedPurchases";
import PurchaseInvoice from "modules/Purchases/PurchaseInvoice/PurchaseInvoice";
import OfferPrice from "modules/Sales/OfferPrice/OfferPrice";
import OrderedSupply from "modules/Sales/OrderedSupply/OrderedSupply";
import SalesBouncer from "modules/Sales/SalesBouncer/SalesBouncer";
import IndexCustomerCodes from "modules/Sales/Customers/IndexCustomerCodes";
import IndexSupplierCodes from "modules/Purchases/SupplierCodes/IndexSupplierCodes";
import IndexDailyRestrictions from "modules/Accounts/DailyRestrictions/IndexDailyRestrictions";
import IndexOpeningRestrictions from "modules/Accounts/OpeningRestrictions/IndexOpeningRestrictions";
import IndexCompanyNews from "modules/SystemSettings/CompanyNews/IndexCompanyNews";
import Loading from "components/Loading/Loading";
import Settings from "modules/SystemSettings/Settings/IndexSettings";
import AccountsSettings from "modules/Accounts/Settings/AccountsSettings";
import PurchasingSettings from "modules/Purchases/Settings/PurchasingSettings";
import SalesSettings from "modules/Sales/Settings/SalesSettings";
import TableCurrencyRate from "modules/FinancialMovement/CurrencyRate/TableCurrencyRate";
import MoveChecks from "modules/FinancialMovement/MoveChecks/MoveChecks";
import FinancialMovementSettings from "modules/FinancialMovement/Settings/Settings";
import IndexReceiptDocument from "modules/FinancialMovement/ReceiptDocument/IndexReceiptDocument";
import IndexCashingDocument from "modules/FinancialMovement/CashingDocument/IndexCashingDocument";
import IndexDailyRestrictionsGL from "modules/FinancialMovement/DailyRestrictions/IndexDailyRestrictionsGL";
import IndexPredecessor from "modules/FinancialMovement/Predecessor/IndexPredecessor";
import IndexTheCovenant from "modules/FinancialMovement/TheCovenant/IndexTheCovenant";
import StoresSettings from "modules/Stores/Settings/Settings";
import IndexMovementOfItems from "modules/Stores/MovementOfItems/IndexMovementOfItems";
import AdditionStep from "modules/Stores/AdditionStep/AdditionStep";
import ExchangeMovement from "modules/Stores/ExchangeMovement/ExchangeMovement";
import IndexStoreTransferMovement from "modules/Stores/StoreTransferMovement/IndexStoreTransferMovement";
import IndexPhysicalInventoryMovement from "modules/Stores/PhysicalInventoryMovement/IndexPhysicalInventoryMovement";
import FollowUpOnOrders from "modules/FollowUpOnOrders/Settings/FollowUpOnOrders";
import IndexCustomer from "modules/FollowUpOnOrders/Customers/IndexCustomer";
import IndexInvoiceDeliveries from "modules/FollowUpOnOrders/InvoiceDeliveries/IndexInvoiceDeliveries";
import ProductionSettings from "modules/Production/Settings/ProductionSettings";
import IndexWorkOrder from "modules/Production/WorkOrder/IndexWorkOrder";
import IndexReceivingTheWorkOrder from "modules/Production/ReceivingTheWorkOrder/IndexReceivingTheWorkOrder";
import IndexExamination from "modules/Production/Examination/IndexExamination";
import IndexReceiveTheFinishedProduct from "modules/Production/ReceiveTheFinishedProduct/IndexReceiveTheFinishedProduct";
import IndexCustomerInstallmentManagement from "modules/InstallmentManagement/Customers/IndexCustomerInstallmentManagement";
import IndexAddData from "modules/InstallmentManagement/AddData/IndexAddData";
import IndexInstallments from "modules/InstallmentManagement/Installments/IndexInstallments";
import IndexProjects from "modules/ProjectsManagement/Projects/IndexProjects";
import IndexProjectsManagement from "modules/ProjectsManagement/ProjectsManagement/IndexProjectsManagement";
import IndexMission from "modules/ProjectsManagement/Mission/IndexMission";
import ProjectsManagementSettings from "modules/ProjectsManagement/Settings/ProjectsManagementSettings";
import IndexContracting from "modules/Contracting/Contracting/IndexContracting";
import AbstractCodes from "modules/Contracting/AbstractCodes/AbstractCodes";
import IndexOperationTerms from "modules/Contracting/OperationTerms/IndexOperationTerms";
import IndexContractorExtract from "modules/Contracting/ContractorExtract/IndexContractorExtract";
import ContractingSettings from "modules/Contracting/Settings/ContractingSettings";
import IndexCustomerCodesMaintenance from "modules/Maintenance/Customers/IndexCustomerCodesMaintenance";
import MaintenanceOrder from "modules/Maintenance/MaintenanceOrder/MaintenanceOrder";
import MaintenanceInvoice from "modules/Maintenance/MaintenanceInvoice/MaintenanceInvoice";
import MaintenanceApostate from "modules/Maintenance/MaintenanceApostate/MaintenanceApostate";
import InitialRequest from "modules/CRM/InitialRequest/InitialRequest";
import SellOrder from "modules/CRM/SellOrder/SellOrder";
import IndexCustomerCRM from "modules/CRM/CustomerCRM/IndexCustomerCRM";
import IndexCustomerFollowUp from "modules/CRM/CustomerFollowUp/IndexCustomerFollowUp";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        // تظهر عند تحميل
        path: "Loading",
        element: <Loading />,
      },
      {
        // {اعدادات النظام}
        // بيانات الشركة
        path: "Settings/CompanyData",
        element: <CompanyData />,
      },
      {
        //   صلاحيات المستخدمين
        path: "Settings/UserPermissions",
        element: <TabsUserPermissions />,
      },
      {
        // تغير كلمه المرور
        path: "Settings/ChangeThePassword",
        element: <ChangeThePassword />,
      },
      {
        // اخبار الشركه
        path: "Settings/Settings",
        element: <Settings />,
      },
      {
        // الاعدادات
        path: "Settings/CompanyNews",
        element: <IndexCompanyNews />,
      },
      // {الحسابات}
      // شجره الحسابات
      {
        path: "Accounts/AccountsTree",
        element: <AccountsTree />,
      },
      // القيود الافتتاحيه
      {
        path: "Accounts/OpeningRestrictions",
        element: <IndexOpeningRestrictions />,
      },
      // القيود اليوميه
      {
        path: "Accounts/DailyRestrictions",
        element: <IndexDailyRestrictions />,
      },
      // الاعدادات
      {
        path: "Accounts/AccountsSettings",
        element: <AccountsSettings />,
      },
      // ---------------------------------------------------------------
      // {الحركه الماليه}
      {
        // اسعار العملات
        path: "FinancialMovement/CurrencyRate",
        element: <TableCurrencyRate />,
      },
      {
        //  مستند قبض
        path: "FinancialMovement/ReceiptDocument",
        element: <IndexReceiptDocument />,
      },
      {
        //  مستند صرف
        path: "FinancialMovement/CashingDocument",
        element: <IndexCashingDocument />,
      },
      {
        //  حركه شيكات
        path: "FinancialMovement/MoveChecks",
        element: <MoveChecks />,
      },
      {
        //   السلفه
        path: "FinancialMovement/Predecessor",
        element: <IndexPredecessor />,
      },
      {
        //   العهد
        path: "FinancialMovement/TheCovenant",
        element: <IndexTheCovenant />,
      },
      {
        //  القيود اليوميه Gl
        path: "FinancialMovement/DailyRestrictions",
        element: <IndexDailyRestrictionsGL />,
      },
      {
        //  الاعدادات
        path: "FinancialMovement/FinancialMovementSettings",
        element: <FinancialMovementSettings />,
      },
      // ---------------------------------------------------------------
      // {المخازن}
      {
        //  حركه الاصناف
        path: "Stores/MovementOfItems",
        element: <IndexMovementOfItems />,
      },
      {
        //  حركه الاضافه
        path: "Stores/AdditionStep",
        element: <AdditionStep />,
      },
      {
        //  حركه الصرف
        path: "Stores/ExchangeMovement",
        element: <ExchangeMovement />,
      },
      {
        //  حركه الجرد الفعلي
        path: "Stores/PhysicalInventoryMovement",
        element: <IndexPhysicalInventoryMovement />,
      },
      {
        //  حركه التحويل المخزني
        path: "Stores/StoreTransferMovement",
        element: <IndexStoreTransferMovement />,
      },
      {
        //  الاعدادات
        path: "Stores/StoresSettings",
        element: <StoresSettings />,
      },
      // ---------------------------------------------------------------
      // {المشتريات}
      {
        // اكواد الموردين
        path: "Purchases/SupplierCodes",
        element: <IndexSupplierCodes />,
      },
      {
        // طلب الشراء
        path: "Purchases/BuyOrder",
        element: <BuyOrder />,
      },
      {
        // أمر شراء
        path: "Purchases/PurchaseOrder",
        element: <PurchaseOrder />,
      },
      {
        // فاتورة مشتريات
        path: "Purchases/PurchaseInvoice",
        element: <PurchaseInvoice />,
      },
      {
        // مرتد مشتريات
        path: "Purchases/ReturnedPurchases",
        element: <ReturnedPurchases />,
      },
      {
        //  الاعدادات
        path: "Purchases/Settings",
        element: <PurchasingSettings />,
      },
      // {مبيعات}
      {
        // العملاء
        path: "Sales/CustomersCode",
        element: <IndexCustomerCodes />,
      },
      {
        // عرض سعر
        path: "Sales/OfferPrice",
        element: <OfferPrice />,
      },
      {
        // امر توريد
        path: "Sales/OrderedSupply",
        element: <OrderedSupply />,
      },
      {
        // مبيعات
        path: "Sales/Sales",
        element: <Sales />,
      },
      {
        // مرتد مبيعات
        path: "Sales/SalesBouncer",
        element: <SalesBouncer />,
      },
      {
        //  الاعدادات
        path: "Sales/Settings",
        element: <SalesSettings />,
      },
      // {متابعه الطلبيات}\
      {
        //  الاعدادات
        path: "FollowUpOnOrders/Settings",
        element: <FollowUpOnOrders />,
      },
      {
        //  العملاء
        path: "FollowUpOnOrders/Customer",
        element: <IndexCustomer />,
      },
      {
        //  تسليمات الفواتير
        path: "FollowUpOnOrders/InvoiceDeliveries",
        element: <IndexInvoiceDeliveries />,
      },
      // {الانتاج}
      {
        //  أمر شغل
        path: "Production/WorkOrder",
        element: <IndexWorkOrder />,
      },
      {
        //  استلام أمر شغل
        path: "Production/ReceivingTheWorkOrder",
        element: <IndexReceivingTheWorkOrder />,
      },
      {
        //  الفحص
        path: "Production/Examination",
        element: <IndexExamination />,
      },
      {
        //  استلام امر شغل
        path: "Production/ReceiveTheFinishedProduct",
        element: <IndexReceiveTheFinishedProduct />,
      },
      {
        //  الاعدادات
        path: "Production/Settings",
        element: <ProductionSettings />,
      },
      // {اداره المشروعات}
      {
        //  المشروعات
        path: "ProjectsManagement/Projects",
        element: <IndexProjects />,
      },
      {
        //  اداره المشروعات
        path: "ProjectsManagement/ProjectsManagement",
        element: <IndexProjectsManagement />,
      },
      {
        //  المهام
        path: "ProjectsManagement/Mission",
        element: <IndexMission />,
      },
      {
        //  الاعدادات
        path: "ProjectsManagement/Settings",
        element: <ProjectsManagementSettings />,
      },
      // { المقاولات}
      {
        //  المقاولين
        path: "Contracting/Contracting",
        element: <IndexContracting />,
      },
      {
        //  اكواد المستخلصات
        path: "Contracting/AbstractCodes",
        element: <AbstractCodes />,
      },
      {
        //  بنود العمليه
        path: "Contracting/OperationTerms",
        element: <IndexOperationTerms />,
      },
      {
        //  مستخلص مقاول
        path: "Contracting/ContractorExtract",
        element: <IndexContractorExtract />,
      },
      {
        //   الاعدادت
        path: "Contracting/Settings",
        element: <ContractingSettings />,
      },
      // {اداره الاقساط}
      {
        //  العملاء
        path: "InstallmentManagement/Customer",
        element: <IndexCustomerInstallmentManagement />,
      },
      {
        //  اضافه بيانات
        path: "InstallmentManagement/AddData",
        element: <IndexAddData />,
      },
      {
        //   الاقساط
        path: "InstallmentManagement/Installments",
        element: <IndexInstallments />,
      },
      // {CRM}
      {
        //  العملاء
        path: "CRM/CustomerCRM",
        element: <IndexCustomerCRM />,
      },
      {
        //  متابعة العملاء
        path: "CRM/CustomerFollowUp",
        element: <IndexCustomerFollowUp />,
      },
      {
        //  طلب مبدئي
        path: "CRM/InitialRequest",
        element: <InitialRequest />,
      },
      {
        //  امر بيع
        path: "CRM/SellOrder",
        element: <SellOrder />,
      },
      // {الصيانه}
      {
        //   العملاء
        path: "Maintenance/Customers",
        element: <IndexCustomerCodesMaintenance />,
      },
      {
        //   امر صيانه
        path: "Maintenance/MaintenanceOrder",
        element: <MaintenanceOrder />,
      },
      {
        //   فاتوره صيانه
        path: "Maintenance/MaintenanceInvoice",
        element: <MaintenanceInvoice />,
      },
      {
        //   مرتد صيانه
        path: "Maintenance/MaintenanceApostate",
        element: <MaintenanceApostate />,
      },
    ],
  },
]);
