import ModalFirstTime from "./Modal/ModalSampleAndGiftsIncoming";
import TableFirstTime from "./TableSampleAndGiftsIncoming";

function IndexSampleAndGiftsIncoming() {
  return (
    <>
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexSampleAndGiftsIncoming;
