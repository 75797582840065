import Heading from "components/Heading/Heading";
import ModalFirstTime from "./Modal/ModalCustomerFollowUp";
import TableFirstTime from "./TableCustomerFollowUp";

function IndexCustomerFollowUp() {
  return (
    <>
      <Heading name={"متابعه العملاء"} />
      <ModalFirstTime />
      {/* جدول عرض البيانات وعمل الاحداث علي البيانات */}
      <TableFirstTime />
    </>
  );
}

export default IndexCustomerFollowUp;
